import React from "react";
import { Link } from "react-router-dom";
import { VegaLogo, VegaLogoSmall } from "assets";
import { useAuthentication } from "domain/Authentication";

import "./user-panel.scss";
export const UserPanel = () => {
  const { user } = useAuthentication();

  return (
    <div className="user__panel" onClick={(e) => e.stopPropagation()}>
      <Link to="" className="user__panel-link">
        <VegaLogo className="user__panel-logo" />
        <VegaLogoSmall className="user__panel-logo-small" />
      </Link>
      <div className="user__panel-profile">
        <div
          className="user__panel-avatar"
          style={{
            backgroundImage: `url("${user.picture}")`,
          }}
        ></div>
        <div className="user__panel-content">
          <span className="user__content-name">{user.name}</span>
          <span className="user__content-email">{user.email}</span>
        </div>
      </div>
    </div>
  );
};

export default UserPanel;
