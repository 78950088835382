import { InventoryMatchingState } from "../../domain/Census/enums";

export const InventoryType = {
  SMALL_INVNENTORY: "smallInventory",
  FIXED_ASSET: "fixedAsset",
  IMMATERIAL_ASSET: "immaterialAsset",
};

export const ExportFileFormat = {
  CSV: "csv",
  XLSX: "xlsx",
};

export const actionTypeStrings = {
  created: "CREATED",
  updated: "UPDATED",
  deleted: "DELETED",
  part_updated: "PART_UPDATED",
  parent_updated: "PARENT_UPDATED",
};

export const actionTypeMap = new Map([
  [actionTypeStrings.created, "created"],
  [actionTypeStrings.updated, "updated"],
  [actionTypeStrings.deleted, "deleted"],
  [actionTypeStrings.part_updated, "part_updated"],
  [actionTypeStrings.parent_updated, "parent_updated"],
]);

export const actionTypeFilters = [
  { name: "Kreiranje inventara", value: "created" },
  { name: "Izmena inventara", value: "updated" },
  { name: "Brisanje inventara", value: "deleted" },
  { name: "Otpis inventara", value: "deleted_wroteOffInventory" },
  { name: "Donacija inventara", value: "deleted_donatedInventory" },
  { name: "Promena vlasnika inventara", value: "updated_ownerId" },
];

export const sortingDirectionMap = new Map([
  ["DESCEND", "desc"],
  ["ASCEND", "asc"],
]);

export const sortingOptions = [
  { value: "created_at", name: "Datumu kreiranja" },
  { value: "purchase_date", name: "Datum nabavke" },
  { value: "purchase_price", name: "Cena nabavke" },
];

export const sortingHistoryOptions = [
  { value: "created_at", name: "Datumu kreiranja" },
  { value: "action_type", name: "Tipu akcije" },
];

export const censusFilterOptions = [
  { value: InventoryMatchingState.ALL_ITEMS, name: "Prikaži sve" },
  { value: InventoryMatchingState.MATCHING, name: "Podaci se poklapaju" },
  { value: InventoryMatchingState.NOT_MATCHING, name: "Podaci se ne poklapaju" },
];

export const exportFileFormats = [
  { key: ExportFileFormat.CSV, name: "csv" },
  { key: ExportFileFormat.XLSX, name: "xlsx" },
];

export const partChangeLabels = new Map([
  ["TOTAL_PRICE", "Promena ukupne cene konfiguracije:"],
  ["IS_PARENT_SMALL_INVENTORY", "Promena tipa konfiguracije:"],
  ["TYPE", "Promena tipa za sve izabrane delove:"],
  ["ASSIGNED_TO", "Dodeljeno kolegi:"],
  ["IS_ARCHIVED", "Arhivirano:"],
  ["LABEL", "Promena naziva:"],
  ["ITEM_ID", "Promena ID-ja:"],
  ["PURCHASE_PRICE", "Promena kupovne cene:"],
  ["TOTAL_PRICE", "Promena ukupne cene:"],
  ["AMORTIZATION_TYPE_ID", "Promena amortizacijskog tipa:"],
  ["SERIAL_NUMBER", "Promena serijskog broja:"],
  ["PURCHASE_DATE", "Promena datuma kupovine:"],
  ["CATEGORY_ID", "Promena kategorije:"],
  ["NAVIGATOR_ID", "Promena ID-ja u Navigatoru:"],
  ["COMPANY_ID", "Promena ID-ja kompanije:"],
  ["COMPANY_NAME", "Promena kompanije korisnika:"],
  ["OWNER_ID", "Promena kompanije vlasnika:"],
  ["ACTIVATION_DATE", "Datum aktiviranja predmeta:"],
  ["INVOICE_NUMBER", "Promena fakture"],
]);

export const partCreateLabels = new Map([
  ["TOTAL_PRICE", "Ukupne cena konfiguracije:"],
  ["IS_PARENT_SMALL_INVENTORY", "Tip konfiguracije:"],
  ["TYPE", "Tip:"],
  ["ASSIGNED_TO", "Dodeljeno kolegi:"],
  ["LABEL", "Naziv:"],
  ["ITEM_ID", "ID:"],
  ["PURCHASE_PRICE", "Kupovna cena:"],
  ["TOTAL_PRICE", "Ukupna cena:"],
  ["AMORTIZATION_TYPE_ID", "Amortizacijski tip:"],
  ["SERIAL_NUMBER", "Serijski broj:"],
  ["PURCHASE_DATE", "Datum kupovine:"],
  ["CATEGORY_NAME", "Kategorija:"],
  ["NAVIGATOR_ID", "ID u Navigatoru:"],
  ["COMPANY_NAME", "Kompanija korisnik:"],
  ["OWNER_ID", "Kompanija vlasnik:"],
  ["INVOICE_NUMBER", "Broj fakture:"],
]);

export const historyExtraTypeMap = new Map([
  ["ASSIGNED_TO", "assignedTo"],
  ["IS_ARCHIVED", "isArchived"],
  ["TYPE", "type"],
  ["LABEL", "label"],
  ["ITEM_ID", "itemId"],
  ["PURCHASE_PRICE", "purchasePrice"],
  ["TOTAL_PRICE", "totalPrice"],
  ["AMORTIZATION_TYPE_ID", "amortizationTypeName"],
  ["SERIAL_NUMBER", "serialNumber"],
  ["PURCHASE_DATE", "purchaseDate"],
  ["CATEGORY_NAME", "categoryName"],
  ["NAVIGATOR_ID", "navigatorId"],
  ["COMPANY_NAME", "companyName"],
  ["OWNER_ID", "ownerName"],
  ["ACTIVATION_DATE", "activationDate"],
  ["INVOICE_NUMBER", "invoiceNumber"],
]);

export const common = {
  PREFERRED_FE_DATE_FORMAT: "DD/MM/YYYY",
  PREFERRED_BE_DATE_FORMAT: "YYYY-MM-DD",
  PREFERRED_TIME_FORMAT: "H:mm:ss",
  MAX_VISABLE_INVENTORY_TABLE_COLUMNS: 8,
  SMALL_INVNENTORY: "Sitan inventar",
  FIXED_ASSET: "Osnovno sredstvo",
  IMMATERIAL_ASSET: "Sredstvo ispod granice materijalnosti",
};

export const invoiceQueryParams = {
  limit: 10,
};

export const DISPLAYED_COLUMNS = "displayedColumns";
export const initialDisplayedColumns = {
  navigatorId: true,
  itemId: true,
  type: true,
  label: true,
  assignedTo: true,
  "category.name": true,
  "company.name": true,
  "company.owner": true,
  "location.office": false,
  activationDate: false,
  "invoice.number": false,
  "invoice.purchaseDate": false,
  supplier: false,
};

export const inventoryTableColumns = [
  {
    key: "navigatorId",
    description: "Navigator id",
  },
  {
    key: "itemId",
    description: "Evidencioni broj",
  },
  {
    key: "type",
    description: "Tip",
  },

  {
    key: "category.name",
    description: "Kategorija",
  },
  {
    key: "label",
    description: "Naziv",
  },
  {
    key: "assignedTo",
    description: "Dodeljeno kolegi",
  },
  {
    key: "company.owner",
    description: "Vlasnik",
  },
  {
    key: "company.name",
    description: "Korisnik",
  },
  {
    key: "location.office",
    description: "Lokacija",
  },
  {
    key: "activationDate",
    description: "Datum aktivacije",
  },
  {
    key: "invoice.number",
    description: "Broj fakture",
  },
  {
    key: "invoice.purchaseDate",
    description: "Datum fakture",
  },
  {
    key: "supplier",
    description: "Dobavljač",
  },
];

export const ARCHIVED_REASON = {
  CreationMistake: "creationMistake",
  Duplicated: "duplicatedInventory",
};

export const WORK_FROM_HOME = "Working from home";
