import React, { useContext, useEffect, useState } from "react";

import { LocationService } from "../services";
import { usePaginatedData } from "shared/hooks/usePaginatedData";

const LocationsContext = React.createContext();

export function useLocationsContext() {
  return useContext(LocationsContext);
}

export function LocationsProvider({ children }) {
  const [locations, setLocations] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { limit, currentPage, resetPage } = usePaginatedData();

  useEffect(() => {
    LocationService.getWorkspaceLocations({ search: searchTerm, page: currentPage, limit }).then((data) => {
      setLocations(data);
    });
  }, [searchTerm, currentPage, limit]);

  const handleLocationSearch = (event) => {
    resetPage();
    setSearchTerm(event);
  };

  return <LocationsContext.Provider value={{ locations, handleLocationSearch }}>{children}</LocationsContext.Provider>;
}
