import React from "react";

import { ModalProvider } from "shared";
import { FiltersProvider } from "domain/Filters";
import { ExportProvider } from "domain/Export";
import { CategoriesProvider } from "domain/Category";
import { CompaniesProvider } from "domain/Company";
import { LocationsProvider } from "domain/Location";
import { InventoryProvider } from "domain/Inventory";
import { SmallInventoryValueProvider } from "domain/SmallInventoryValue";
import { SortingProvider } from "domain/Sorting";
import { AmortizationTypeProvider } from "domain/AmortizationType";
import { InvoicesProvider } from "domain/Invoice";
import { EmployeesProvider } from "domain/Employee";
import { CensusProvider } from "../../Census";
import { SuppliersProvider } from "domain/Supplier/context";
import { HistoryProvider } from "domain/History";

export const ApplicationContext = React.createContext();

export function ApplicationContextProvider({ children }) {
  return (
    <ApplicationContext.Provider>
      <SortingProvider>
        <FiltersProvider>
          <CategoriesProvider>
            <CompaniesProvider>
              <CensusProvider>
                <SuppliersProvider>
                  <InvoicesProvider>
                    <LocationsProvider>
                      <EmployeesProvider>
                        <SmallInventoryValueProvider>
                          <AmortizationTypeProvider>
                            <ExportProvider>
                              <HistoryProvider>
                                <InventoryProvider>
                                  <ModalProvider>{children}</ModalProvider>
                                </InventoryProvider>
                              </HistoryProvider>
                            </ExportProvider>
                          </AmortizationTypeProvider>
                        </SmallInventoryValueProvider>
                      </EmployeesProvider>
                    </LocationsProvider>
                  </InvoicesProvider>
                </SuppliersProvider>
              </CensusProvider>
            </CompaniesProvider>
          </CategoriesProvider>
        </FiltersProvider>
      </SortingProvider>
    </ApplicationContext.Provider>
  );
}
