import "./sorting-group.scss";
import React from "react";
import { Button, Dropdown } from "shared";
import { useSortingContext } from "../../context";
import { sortingDirectionMap } from "shared/constants/constants";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { useHistoryContext } from "domain/History";

export const SortingGroup = ({ resetChoices, onItemClick, sortingOptions }) => {
  const { pendingSort, setPendingSort } = useSortingContext();
  const { pendingHistorySort, setPendingHistorySort } = useHistoryContext();

  const changeDirection = () => {
    if (
      pendingSort.direction === sortingDirectionMap.get("DESCEND") ||
      pendingHistorySort.direction === sortingDirectionMap.get("DESCEND")
    ) {
      setPendingSort({ name: pendingSort.name, key: pendingSort.key, direction: sortingDirectionMap.get("ASCEND") });
      setPendingHistorySort({
        name: pendingHistorySort.name,
        key: pendingHistorySort.key,
        direction: sortingDirectionMap.get("ASCEND"),
      });
    } else {
      setPendingSort({ name: pendingSort.name, key: pendingSort.key, direction: sortingDirectionMap.get("DESCEND") });
      setPendingHistorySort({
        name: pendingHistorySort.name,
        key: pendingHistorySort.key,
        direction: sortingDirectionMap.get("DESCEND"),
      });
    }
  };

  return (
    <div className="sorting">
      <label htmlFor="sorting__dropdown" className="sorting__text">
        Sortiraj po:
      </label>
      <div id="sorting__dropdown" className="sorting__field">
        <div className="flex">
          {sortingOptions.length > 1 && (
            <Dropdown
              choices={sortingOptions.map(({ name, value }) => ({
                name,
                key: value,
              }))}
              onChange={(selectedOptions) => {
                const selected = selectedOptions.pop();
                setPendingSort({ ...selected, direction: pendingSort.direction });
                setPendingHistorySort({ ...selected, direction: pendingHistorySort.direction });
              }}
              isMultipleChoice={false}
              allSelectedLabel={Object.keys(pendingSort).length === 0 ? "-/-" : pendingSort.name}
              resetChoices={resetChoices}
              onItemClick={onItemClick}
            />
          )}
          <Button
            buttonColor="gray"
            event={changeDirection}
            buttonIcon={pendingSort.direction === sortingDirectionMap.get("DESCEND") ? <FaArrowDown /> : <FaArrowUp />}
          />
        </div>
      </div>
    </div>
  );
};
