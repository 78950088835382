import { StickerPreview } from "domain/Print";
import React from "react";
import { SCAN } from "router";
import "./item-parts.scss";

export const ItemParts = ({ item }) => {
  return (
    <section className="parts">
      <header className="parts__header">
        <h3 className="parts__title">Povezani delovi</h3>
        <p className="parts__tooltip">(Prevucite prstom da biste videli ostale podatke)</p>
      </header>
      <div className={`parts__content${item.parts.length > 1 ? " parts__content--scroll" : ""}`}>
        {item.parts.map((part) => {
          const { itemId, label, serialNumber, category, company } = part;
          return (
            <StickerPreview
              key={itemId}
              title={itemId}
              subtitle={label}
              description={serialNumber}
              url={`${window.location.origin}${SCAN}/${itemId}`}
              footer={[category?.name, company?.name]}
            />
          );
        })}
      </div>
    </section>
  );
};
