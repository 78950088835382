import { ItemDisplayValue } from "../item-display-value/item-display-value";
import moment from "moment";
import { common } from "shared/constants/constants";

export const ItemDisplayInvoice = ({ item }) => {
  const checkSupplier = item.supplier ? item.supplier.name : "Nepoznat dobavljač";

  return (
    <>
      {item.invoice ? (
        <>
          <ItemDisplayValue label="Broj" value={item.invoice.number} />
          <ItemDisplayValue
            label="Datum izdavanja"
            value={moment(item.invoice.purchaseDate).format(common.PREFERRED_FE_DATE_FORMAT)}
          />
          <ItemDisplayValue label="Dobavljač" value={checkSupplier} />
        </>
      ) : (
        "Nepoznata faktura"
      )}
    </>
  );
};
