import React from "react";
import { StickerPreview } from "../sticker-preview/sticker-preview";
import { SCAN } from "router";

export const ItemSticker = React.forwardRef(({ item }, ref) => {
  const { itemId, label, serialNumber, category, company } = item;
  return (
    <StickerPreview
      ref={ref}
      title={itemId}
      subtitle={label}
      description={serialNumber}
      footer={[category?.name, company?.name]}
      url={`${window.location.origin}${SCAN}/${itemId}`}
    />
  );
});
