import { ItemTypeLabel } from "domain/Inventory";
import { useSmallInventoryValueContext } from "domain/SmallInventoryValue";
import { useEffect } from "react";
import { SqueezeBox } from "shared";
import { ArrowRight } from "assets";
import "./preview-changes-small-inventory-value.scss";
import { useCallback } from "react";

export const PreviewChangesSmallInventoryValue = ({ smallInventoryValue }) => {
  const { previewChangesSmallInventoryValues, inventoryPreviews } = useSmallInventoryValueContext();
  useEffect(() => {
    if (!smallInventoryValue) return;
    previewChangesSmallInventoryValues(smallInventoryValue);
  }, [smallInventoryValue, previewChangesSmallInventoryValues]);

  const PreviewChanges = useCallback(() => {
    if (!inventoryPreviews.length) return null;
    return (
      <SqueezeBox>
        <div>Sve promene</div>
        <div className="preview-changes-small-inventory-value__body">
          <table>
            <tbody>
              {inventoryPreviews.map((item) => {
                return (
                  <tr key={item.id} className="small-inventory-value-settings__item-value">
                    <td className="small-inventory-value-settings__item-id" title={item.itemId}>
                      {item.itemId}
                    </td>
                    <td className="small-inventory-value-settings__item-value-label">
                      <ItemTypeLabel type={item.type} />
                    </td>
                    <td>
                      <ArrowRight style={{ height: "10px" }} />
                    </td>
                    <td className="small-inventory-value-settings__item-value-label">
                      <ItemTypeLabel type={item.type} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </SqueezeBox>
    );
  }, [inventoryPreviews]);

  return (
    <div className="preview-changes-small-inventory-value">
      <div className="preview-changes-small-inventory-value__total-changes">
        Ukupan broj promena: <span>{inventoryPreviews?.length}</span>
      </div>
      <PreviewChanges />
    </div>
  );
};
