import { api } from "../../../shared";

const url = "/api/inventory-census";

async function addItem(item) {
  return api
    .inventar({
      method: "post",
      url,
      data: item,
    })
    .then((response) => response.data);
}

async function updateDetails(updateData) {
  const { inventoryId, isMatching, description, censusId} = updateData;
  const data = {
    inventoryId,
    censusId,
    isMatching,
    description,
  };

  return api
    .inventar({
      method: "put",
      url: `${url}`,
      data,
    })
    .then((response) => response.data);
}

export const InventoryCensusService = {
  addItem,
  updateDetails,
};
