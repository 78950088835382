import clsx from "clsx";
import { TextInput, Dropdown } from "shared";
import { useCompaniesContext } from "domain/Company";
import { ItemTypeLabel } from "domain/Inventory";
import { INPUT_FIELD_MAX_LENGTH } from "environment";

export const WorkStationItemTemplate = ({
  values,
  handleBlur,
  isFieldDirty,
  touched,
  isDropdownDirty,
  onInputChange,
  onDropdownChange,
  showInventoryType,
  isItemUpdate,
}) => {
  const { companies } = useCompaniesContext();
  return (
    <>
      <TextInput
        onChange={onInputChange}
        name="label"
        label="Naziv predmeta"
        inputError={isFieldDirty("label")}
        value={values.label}
        maxLength={INPUT_FIELD_MAX_LENGTH}
        onBlur={handleBlur}
        required
      />

      {!isItemUpdate && (
        <div className="dropdown-form__wrapper">
          <label>
            Vlasnik<span className="text-input__required">*</span>
          </label>
          <Dropdown
            choices={companies}
            onChange={onDropdownChange("owner")}
            allSelectedLabel="Odaberite vlasnika"
            defaultValue={[values.company]}
            handleBlur={handleBlur}
            touched={touched}
            dropdownError={isDropdownDirty("owner")}
            name={"owner"}
            disabled={isItemUpdate}
          />
        </div>
      )}
      <div className="dropdown-form__wrapper">
        <label>
          Korisnik: <span className="text-input__required">*</span>
        </label>
        <Dropdown
          choices={companies}
          onChange={onDropdownChange("company")}
          allSelectedLabel="Odaberite korisnika"
          defaultValue={[values.company]}
          handleBlur={handleBlur}
          touched={touched}
          dropdownError={isDropdownDirty("company")}
          name={"company"}
        />
      </div>
      <div className={clsx("item-attributes__is-small-inventory", { show: showInventoryType })}>
        <ItemTypeLabel type={values.type} />
      </div>

      <TextInput onChange={onInputChange} value={values.assignedTo} label="Dodeljeno kolegi" name="assignedTo" />
      {!isItemUpdate && (
        <TextInput
          type="number"
          onChange={onInputChange}
          value={values.count}
          label="Broj komada"
          name="count"
          inputError={isFieldDirty("count")}
        />
      )}
    </>
  );
};
