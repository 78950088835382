import React, { useContext, useEffect, useState } from "react";

import { CategoryService } from "../services";

const CategoriesContext = React.createContext();

export function useCategoriesContext() {
  return useContext(CategoriesContext);
}

export function CategoriesProvider({ children }) {
  const [categories, setCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    CategoryService.get().then(setCategories);
  }, []);

  const addCategory = (newCategory) =>
    CategoryService.add(newCategory).then((category) => setCategories((values) => [...values, category]));

  const deleteCategory = (id) =>
    CategoryService.remove(id).then(() => setCategories((prev) => prev.filter((category) => category.id !== id)));

  const handleCategoriesSearch = (searchInput) => {
    setSearchTerm(searchInput.toLowerCase());
  };

  const categoriesFilter = categories.filter((cat) => cat.name.toLowerCase().includes(searchTerm));

  return (
    <CategoriesContext.Provider
      value={{ categories, addCategory, deleteCategory, handleCategoriesSearch, categoriesFilter }}
    >
      {children}
    </CategoriesContext.Provider>
  );
}
