import { CensusTable } from "../census-table/census-table";
import { getIsMatchingText } from "../../helpers";

export const CensusDataContent = ({
  data,
  columns,
  handleEditClick,
  handleRowClick,
  isMatchingFilter,
  isActiveCensus,
}) => {
  if (data.length === 0) {
    return <div>Nema podataka</div>;
  }

  return (
    <div className="scroll">
      <CensusTable
        columns={columns}
        data={data}
        handleEditClick={handleEditClick}
        handleRowClick={handleRowClick}
        getIsMatchingText={getIsMatchingText}
        isMatchingFilter={isMatchingFilter}
        isActiveCensus={isActiveCensus}
      />
    </div>
  );
};
