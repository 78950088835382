import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { usePagination, useTable } from "react-table";
import { PenIcon } from "../../../../assets";
import { ItemsTablePagination } from "../../../Inventory";
import { useFiltersContext } from "../../../Filters";

export const CensusTable = React.forwardRef(
  ({ columns, data, handleEditClick, handleRowClick, getIsMatchingText, isActiveCensus, resetScroll }, ref) => {
    const { itemsPerView, setItemsPerView } = useFiltersContext();
    const [scrollActive, setScrollActive] = useState(false);

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      canNextPage,
      canPreviousPage,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0, pageSize: itemsPerView },
      },
      usePagination
    );

    useEffect(() => {
      setPageSize(itemsPerView);
    }, [itemsPerView, setPageSize]);

    const handlePageSizeChange = (newPageSize) => {
      setItemsPerView(newPageSize);
    };

    const onScrollHandler = (e) => {
      const itemYPos = e.currentTarget.scrollTop;
      setScrollActive(itemYPos > 20);
    };

    return (
      <>
        <div className="items-table-wrap census-table-items-wrap" onScroll={onScrollHandler} ref={ref}>
          <div role="table" className="census-details-item-table" {...getTableProps()}>
            <div className={clsx("items-table__header", { "items-table__header--sticky": scrollActive })}>
              {headerGroups.map((headerGroup) => (
                <div
                  {...headerGroup.getHeaderGroupProps()}
                  className={clsx("items-table__heading", "details-table-header")}
                >
                  {headerGroup.headers.map((column) => (
                    <div {...column.getHeaderProps()} key={column.id} className="items-table__heading">
                      {column.render("Header")}
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <div className="items-table__body" {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <div role="row" className="items-table__row census-details-table__grid" {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      const doesNotMatch = row.original.isMatching === false;
                      return (
                        <div role="cell" className="items-table__data" {...cell.getCellProps()}>
                          {cell.column.Header === "" && doesNotMatch && isActiveCensus ? (
                            <PenIcon onClick={() => handleEditClick(row.original.inventoryId)} />
                          ) : (
                            <div onClick={() => handleRowClick(row.original.itemId)}>{getIsMatchingText(cell)}</div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="items-pagination">
          <ItemsTablePagination
            pageCount={pageCount}
            pageIndex={pageIndex}
            gotoPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            pageSize={pageSize}
            onPageSizeChange={handlePageSizeChange}
          />
        </div>
      </>
    );
  }
);
