import React, { useCallback } from "react";

import { TextInput } from "shared";

import "./new-amortization-type.form.scss";
import { useFormik } from "formik";
import { formSchema } from "./new-amortization-type-form-schema";

export const CreateNewAmortizationTypeForm = ({ onSubmit, onCancel }) => {
  const { setValues, errors, values, touched, handleSubmit } = useFormik({
    initialValues: {
      name: "",
      amortizationRate: "",
    },
    validationSchema: formSchema,
    initialErrors: { name: "error", amortizationRate: "error" },
    onSubmit: () => onSubmit(values),
  });

  const onInputChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      setValues((oldData) => ({ ...oldData, [name]: name === "amortizationRate" ? Number(value).toString() : value }));
    },
    [setValues]
  );

  const isFieldDirty = useCallback(
    (field) => {
      return touched[field] && errors[field];
    },
    [touched, errors]
  );

  return (
    <>
      <div className="amortization-type-form">
        <h2 className="amortization-type-form__title">Dodavanje novog tipa amortizacije</h2>
        <TextInput
          name="name"
          onChange={onInputChange}
          autoComplete="off"
          placeholder="Naziv tipa amortizacije"
          value={values.name || ""}
          inputError={isFieldDirty("name")}
        />
        <TextInput
          type="number"
          name="amortizationRate"
          onChange={onInputChange}
          autoComplete="off"
          value={values.amortizationRate}
          placeholder="Stopa amortizacije (%)"
          inputError={isFieldDirty("amortizationRate")}
        />
      </div>

      <div className="amortization-type-form__controls">
        <button type="button" className="btn btn--blue" onClick={handleSubmit}>
          Potvrdi
        </button>
        <button type="button" className="btn btn--gray" onClick={onCancel}>
          Otkaži
        </button>
      </div>
    </>
  );
};
