import React, { useContext, useEffect, useState } from "react";

import { InvoiceService } from "../services/invoice.service";
import { useSuppliersContext } from "domain/Supplier/context";

const InvoicesContext = React.createContext({});

export function useInvoicesContext() {
  return useContext(InvoicesContext);
}

export function InvoicesProvider({ children }) {
  const [invoices, setInvoices] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { suppliers } = useSuppliersContext();

  const handleInvoicesSearch = (event) => {
    setSearchTerm(event);
  };

  useEffect(() => {
    InvoiceService.get({ searchTerm: searchTerm }).then((invoices) => {
      const invoicesWithSuppliers = invoices.map((invoice) => ({
        ...invoice,
        supplier: suppliers.find((supplier) => supplier.id === invoice.supplierId),
      }));
      setInvoices(invoicesWithSuppliers);
    });
  }, [searchTerm, suppliers]);

  return (
    <InvoicesContext.Provider
      value={{
        invoices,
        handleInvoicesSearch,
      }}
    >
      {children}
    </InvoicesContext.Provider>
  );
}
