import { useCallback } from "react";
import { useFormik } from "formik";
import { InputSwitch } from "shared";
import { common } from "shared/constants/constants";
import "./preference-form.scss";
import { Button } from "shared";
import { inventoryTableColumns } from "shared/constants/constants";

export const PreferencesForm = ({ displayedColumns, onSubmit, onCancel }) => {
  const { setValues, values } = useFormik({
    initialValues: displayedColumns,
  });

  const numberOfCheckedColumns = Object.values(values).filter((value) => value === true).length;
  const disableCheckingCondition = numberOfCheckedColumns >= common.MAX_VISABLE_INVENTORY_TABLE_COLUMNS;
  const submitButtonDisableCondition = numberOfCheckedColumns < 1;

  const onInputSwitchChange = useCallback(
    (field) => (event) => {
      setValues((prev) => ({
        ...prev,
        [field]: !values[field],
      }));
    },
    [setValues, values]
  );

  const formSubmitHandler = useCallback(
    async (e) => {
      e.preventDefault();
      onSubmit(values);
    },

    [onSubmit, values]
  );

  return (
    <>
      <h2 className="preferences_title">Podešavanje prikaza inventara</h2>
      <h3 className="columns_title">Prikazane kolone</h3>
      <form>
        {inventoryTableColumns.map((column) => {
          const columnKey = column.key;

          return (
            <InputSwitch
              key={columnKey}
              disabled={!values[columnKey] && disableCheckingCondition}
              checkedLabel=""
              uncheckedLabel=""
              onChange={onInputSwitchChange(columnKey)}
              description={column.description}
              name={columnKey}
              isChecked={values[columnKey]}
            />
          );
        })}
      </form>

      <div className="preferences_foother_info">Broj prikazanih kolona: {numberOfCheckedColumns} / 8</div>
      {disableCheckingCondition && (
        <div className="preferences_foother_info__warning">Moguće je prikazati najviše 8 kolona.</div>
      )}
      <div className="preferences_controls_group">
        <Button buttonText={"Otkaži"} event={onCancel} />
        <Button
          type="submit"
          isDisabled={submitButtonDisableCondition}
          buttonText={"Sačuvaj"}
          buttonColor={"green"}
          event={formSubmitHandler}
          spinnerColor={"light"}
        />
      </div>
    </>
  );
};
