import React from "react";
import { ProgressBar, TextInput } from "shared";
import "./census-progress.scss";

export const CensusProgress = ({ progress, showDetailedProgress, toggleDetailedProgress }) => {
  if (!progress) return null;

  const { mainProgress, detailedProgress } = progress;
  return (
    <div className="census-progress">
      <div className="progress-header">
        <p>
          Ukupan progres za sve kategorije:
          <br />
          <span className="completed">Potvrđeno: {mainProgress.completed}</span>
          <br />
          <span className="pending">Nepotvrđeno: {mainProgress.pending}</span>
          <br />
          <span className="incomplete"> Nepopisano: {mainProgress.incomplete}</span>
          <br />
          Ukupno: (
          <span>
            <b>{mainProgress.total}</b>
          </span>
          )
        </p>
      </div>
      <ProgressBar progress={(mainProgress.completed / mainProgress.total) * 100} />
      {showDetailedProgress && detailedProgress.length > 0 && (
        <div className="detailed-progress">
          <h3>Detaljan progres po kategorijama:</h3>
          {detailedProgress.map(({ categoryName, total, completed, pending, incomplete }) => (
            <div key={categoryName} className="progress-item">
              <p className="category-name">
                {categoryName}: <span className="completed">{completed}</span>/
                <span className="pending">{pending}</span>/<span className="incomplete">{incomplete}</span>(
                <span>
                  <b>{total}</b>
                </span>
                )
              </p>
              <ProgressBar progress={(completed / total) * 100} />
            </div>
          ))}
        </div>
      )}

      <div className="detailed-progress-check">
        <TextInput
          name="showDetailedProgress"
          type="checkbox"
          checked={showDetailedProgress}
          onChange={toggleDetailedProgress}
          label="Prikaži detaljan progres"
        />
      </div>
    </div>
  );
};
