import React from "react";

import { Button, ScrollPosition } from "shared";
import { InventoryService } from "domain/Inventory/services";
import { ExportFiltersGroup, useDownloadFile } from "domain/Export";
import { useExportContext } from "../../context";
import { ItemsTable } from "domain/Inventory";
import { common } from "shared/constants/constants";
import { initialDisplayedColumns, DISPLAYED_COLUMNS } from "shared/constants/constants";
import { useLocalStorage } from "domain/App";

import moment from "moment";
import "./export.page.scss";

const CSV_FILE_CONTENT_TYPE = "text/csv";
const XLSX_FILE_CONTENT_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

export const ExportPage = () => {
  const { applyExportFilters, exportItems } = useExportContext();
  const [displayedColumns] = useLocalStorage(DISPLAYED_COLUMNS, initialDisplayedColumns);
  const { onDownloadFile } = useDownloadFile();

  const onExport = () => {
    const params = { ...applyExportFilters };
    InventoryService.exportData(params).then((response) => {
      const data = response.data;
      const contentType = response.headers["content-type"];
      const dateString = moment(new Date()).format(common.PREFERRED_FE_DATE_FORMAT);
      if (contentType.includes(CSV_FILE_CONTENT_TYPE)) {
        const csvBlob = new Blob([data], { type: CSV_FILE_CONTENT_TYPE });
        onDownloadFile(csvBlob, "inventar-export-" + dateString + ".csv");
      } else if (contentType.includes(XLSX_FILE_CONTENT_TYPE)) {
        const xlsxBlob = new Blob([data], { type: XLSX_FILE_CONTENT_TYPE });
        onDownloadFile(xlsxBlob, "inventar-export-" + dateString + ".xlsx");
      }
    });
  };

  return (
    <>
      <div className="home-header">
        <div className="home-header_left">
          <ExportFiltersGroup />
        </div>
        <div className="home-header_right">
          <div className="home-header_right_primary_cta">
            <Button buttonText={"Export"} buttonColor={"blue"} event={onExport} isDisabled={!exportItems.length} />
          </div>
        </div>
      </div>
      <div className="export-items-table-container">
        <ScrollPosition uniqueId="export-dashboard-page">
          <ItemsTable displayedColumns={displayedColumns} data={exportItems} />
        </ScrollPosition>
      </div>
    </>
  );
};
