import * as yup from "yup";

export const formSchema = yup.object().shape({
  name: yup.string().max(100, "Polje može sadržati najivše 100 karaktera").required("Obavezno Polje"),

  amortizationRate: yup
    .number()

    .max(100, "Stopa amortizacije ne može biti veća od 100")
    .min(0, "Stopa amortizacije ne može biti negativna vrednost")

    .required("Obavezno Polje"),
});
