import { VegaLogo } from "assets";
import { SendTextField, useInventoryContext } from "domain/Inventory";
import React, { useEffect, useMemo, useState } from "react";
import { ItemParts } from "domain/Inventory/components/item-parts/item-parts";
import { useParams } from "react-router-dom";
import { Button, Spinner, useModalContext } from "shared";
import QRCode from "qrcode.react";
import { SCAN } from "router";

import "./scan.page.scss";
import { useCensusContext } from "../../../Census";

export const ScanPage = () => {
  const [item, setItem] = useState();
  const [textField, setTextField] = useState("");
  const [loading, setLoading] = useState(true);
  const [censusMessage, setCensusMessage] = useState("");
  const [alreadyCensused, setAlreadyCensused] = useState(false);
  const { id } = useParams();
  const { items, addItemToCensus } = useInventoryContext();
  const { activeCensus, getCensusInventories } = useCensusContext();
  const { createModal } = useModalContext();
  const { element: CreateNewItemModal, open, close } = useMemo(() => createModal(), [createModal]);

  useEffect(() => {
    if (!id || !items) return;
    const item = items.find((inventory) => inventory.itemId === id);
    setItem(item);
  }, [id, items]);

  useEffect(() => {
    if (!activeCensus) {
      setLoading(false);
      return setCensusMessage("Ne postoji trenutno aktivan popis");
    }
    const queryParams = { itemId: id };
    getCensusInventories(activeCensus.id, queryParams)
      .then((data) => {
        const isCensusedInventory = data[0].isMatching !== null;
        setAlreadyCensused(isCensusedInventory);
        const message = isCensusedInventory
          ? `Inventar je već popisan za trenutno aktivni popis (${activeCensus.year})`
          : `Ovaj inventar će biti dodat u trenutno aktivni popis (${activeCensus.year})`;
        setCensusMessage(message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [getCensusInventories, id, activeCensus, items]);

  const submitHandle = (isMatching) => {
    const itemForCensus = {
      inventoryId: item.id,
      censusId: activeCensus.id,
      isMatching,
      description: textField ?? "-",
    };
    addItemToCensus(itemForCensus).then(() => {
      alert(`Inventar je uspešno zaveden za popis ${activeCensus?.year}`);
      setAlreadyCensused(true);
    });
  };

  const changeTextField = (e) => setTextField(e.target.value);

  if (loading || !items) return <Spinner text="Sadržaj se učitava" size="large" />;
  if (!item) return <h2 className="scan__error">Ne postoji inventar sa traženim evidencionim brojem {id}</h2>;

  return (
    <div className="scan">
      <header className="scan__header">
        <VegaLogo className="scan__logo" />
        <section className="scan__item-info">
          <QRCode
            id={`${window.location.origin}${SCAN}/${item.itemId}`}
            value={`${window.location.origin}${SCAN}/${item.itemId}`}
            size={60}
            level={"M"}
            renderAs="svg"
          />
          <h2 className="scan__title">{item.itemId}</h2>
        </section>
      </header>
      <div className="scan__wrap">
        <div className="scan__main">
          <div className="scan__item">
            <div className="scan__content">
              <div className="scan__item-wrap scan__item-wrap--single">
                <span className="scan__label">Naziv</span>
              </div>
              <div className="scan__item-wrap scan__item-wrap--single">
                <p className="scan__data">{item.label}</p>
              </div>
            </div>
            <div className="scan__content">
              <div className="scan__item-wrap">
                <span className="scan__label">Serijski broj</span>
                <span className="scan__label">Dodeljeno kolegi</span>
              </div>
              <div className="scan__item-wrap">
                <p className="scan__data">{item.serialNumber}</p>
                <p className="scan__data">{item.assignedTo}</p>
              </div>
            </div>
            <div className="scan__content">
              <div className="scan__item-wrap">
                <span className="scan__label">Grad</span>
                <span className="scan__label">Adresa</span>
              </div>
              <div className="scan__item-wrap">
                <p className="scan__data">{item?.location?.city}</p>
                <p className="scan__data">{item?.location?.address}</p>
              </div>
            </div>
            <div className="scan__content">
              <div className="scan__item-wrap">
                <span className="scan__label">Sprat</span>
                <span className="scan__label">Kancelarija</span>
              </div>
              <div className="scan__item-wrap">
                <p className="scan__data">{item?.location?.floor}</p>
                <p className="scan__data">{item?.location?.office}</p>
              </div>
            </div>
            {censusMessage}
          </div>
          <section className="scan__confirmed" hidden={!activeCensus || alreadyCensused}>
            <h3 className="scan__confirmed-title">Da li su podaci o ovom predmetu tačni?</h3>
            <div className="scan__btns">
              <Button
                buttonText={"Da"}
                buttonColor={"green"}
                event={() => submitHandle(true)}
                isDisabled={item.isConfirmed}
              />
              <Button buttonText={"Ne"} buttonColor={"red"} event={open} isDisabled={item.isConfirmed} />
            </div>
            <CreateNewItemModal>
              <SendTextField
                text={textField}
                handleSubmit={() => submitHandle(false)}
                handleChange={changeTextField}
                handleClose={close}
              />
            </CreateNewItemModal>
          </section>
        </div>
        {item.parts.length > 0 && <ItemParts item={item} />}
      </div>
    </div>
  );
};
