import React, { useMemo } from "react";
import { StickerPreview } from "domain/Print";
import { useLocation, useNavigate } from "react-router-dom";
import { useCompaniesContext } from "domain/Company";
import { useCategoriesContext } from "domain/Category";
import { SCAN } from "router";
import clsx from "clsx";

import "./inventory-parts.scss";

export const InventoryParts = ({ parts, currentActiveCensus }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { companies } = useCompaniesContext();
  const { categories } = useCategoriesContext();

  const companyName = (id) => {return companies.find((company) => company.id === id).name };
  const categoryName = (id) => {return categories.find((category) => category.id === id).name };

  const numberOfParts = useMemo(() => {
    if (parts?.length < 2) return "";

    if (parts.length > 3) {
      return " inventory-config__content--parts-left";
    }
    return " inventory-config__content--parts-one-row";
  }, [parts]);

  const handleClick = (itemId) => {
    if (pathname !== `/item/${itemId}`) {
      navigate(`/item/${itemId}`);
    }
  };

  return (
    <div className={`inventory-config__content inventory-config__content--parts${numberOfParts}`}>
      {parts?.map(({ itemId, label, serialNumber, categoryId, companyId, activeCensus }) => {
        const itemCensusStatus =
          activeCensus?.isMatching === undefined ? "incomplete" : activeCensus.isMatching ? "completed" : "pending";

        return (
          <div
            className={`inventory-config__item${
              pathname === `/item/${itemId}` ? " inventory-config__item--current" : ""
            }`}
            key={itemId}
            onClick={() => handleClick(itemId)}
          >
            <StickerPreview
              key={itemId}
              title={itemId}
              subtitle={label}
              description={serialNumber}
              url={`${window.location.origin}${SCAN}/${itemId}`}
              footer={[categoryName(categoryId), companyName(companyId)]}
              badge={currentActiveCensus && <div className={clsx("census-badge", { [itemCensusStatus]: true })} />}
            />
          </div>
        );
      })}
    </div>
  );
};
