import React, { useState } from "react";
import { useReactToPrint } from "react-to-print";
import { Button } from "shared";

import "./print-element.scss";

export const PrintElement = ({ elementRef, width, height, buttonText }) => {
  const [loading, setLoading] = useState(false);

  const print = useReactToPrint({
    content: () => elementRef.current,
    pageStyle: `@page {
        size: ${width} ${height};
        margin: 0;
        padding: 0;
      }`,
    onAfterPrint: () => {
      setLoading(false);
    },
  });

  const handleClick = () => {
    setLoading(true);
    print();
  };

  return (
    <Button
      buttonText={buttonText}
      buttonColor={"blue"}
      event={handleClick}
      spinnerColor={"light"}
      isLoading={loading}
    />
  );
};

export default PrintElement;
