import { api } from "../../../shared";

const url = "/api/companies";

async function get() {
  return api
    .inventar({
      method: "get",
      url,
    })
    .then((response) => response.data);
}

async function add(category) {
  return api
    .inventar({
      method: "post",
      url,
      data: category,
    })
    .then((response) => response.data);
}

async function remove(companyId) {
  return api.inventar({
    method: "delete",
    url: `${url}/${companyId}`,
  });
}

export const CompanyService = {
  add,
  get,
  remove,
};
