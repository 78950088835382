import React, { useState, useEffect } from "react";

import { useCompaniesContext } from "domain/Company";
import { StickerPreview } from "domain/Print";

import { Button, TextInput } from "shared";

import "./create-new-company.form.scss";
import { INPUT_FIELD_MAX_LENGTH } from "environment";

export const CreateNewCompanyForm = ({ onSubmit, onCancel, isBtnLoading }) => {
  const [newCompany, setNewCompany] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [isUnique, setIsUnique] = useState(true);
  const { companies } = useCompaniesContext();

  const onInputChange = (event) => {
    const { name: key, value } = event.target;
    setNewCompany((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    setIsValid(newCompany.name?.length && newCompany.label?.length && newCompany.label?.length <= 10);
    const isUniqueName = (obj) => obj.name === newCompany.name;
    const isUniqueLabel = (obj) => obj.label === newCompany.label;
    setIsUnique(!companies.some(isUniqueName) && !companies.some(isUniqueLabel));
  }, [newCompany, companies]);

  const confirm = () => {
    if (!isValid) return alert("Popunite sva ponuđena polja, naziv labele mora biti manji ili jednak od 10 karaktera");
    if (!isUnique) return alert("Naziv vlasništva i naziv labele moraju biti jedinstveni");
    onSubmit(newCompany);
  };

  return (
    <>
      <div className="create-company">
        <h2 className="create-company__title"> Dodavanje nove kompanije</h2>
        <TextInput
          name="name"
          onChange={onInputChange}
          autoComplete="off"
          placeholder="Naziv kompanije"
          value={newCompany.name || ""}
          maxLength={INPUT_FIELD_MAX_LENGTH}
        />
        <TextInput
          name="label"
          onChange={onInputChange}
          autoComplete="off"
          value={newCompany.label || ""}
          placeholder="Naziv labele"
        />
        <StickerPreview
          title={`${newCompany?.label || "?"}-TV-123`}
          subtitle="Samsung TV"
          footer={["Televizor", newCompany?.name || "?"]}
          url="https://inventar.vegait.rs/"
        />
      </div>
      <div className="create-company__controls">
        <Button
          buttonText={"Potvrdi"}
          buttonColor={"red"}
          event={confirm}
          spinnerColor={"light"}
          isLoading={isBtnLoading}
        />
        <Button buttonText={"Otkaži"} buttonColor={"gray"} event={onCancel} />
      </div>
    </>
  );
};
