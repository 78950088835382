import React, { useEffect, useCallback, useContext, useState } from "react";
import { CensusService } from "../services";
import { InventoryCensusService } from "../services/inventory-census.service";

const CensusContext = React.createContext();

export function useCensusContext() {
  return useContext(CensusContext);
}

export function CensusProvider({ children }) {
  const [censuses, setCensuses] = useState([]);
  const [activeCensus, setActiveCensus] = useState(null);
  const [censusInventories, setCensusInventories] = useState(null);
  const [notInCensusInventories, setNotInCensusInventories] = useState(null);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    CensusService.get().then((censuses) => {
      const hasActiveCensus = censuses.find((census) => census.isActive);
      setCensuses(censuses);
      if (hasActiveCensus) setActiveCensus(hasActiveCensus);
    });
  }, []);

  const getCensuses = useCallback(() => {
    CensusService.get().then(setCensuses);
  }, []);

  const isActiveCensus = (censusId) => {
    const census = censuses.find((census) => census.id === Number(censusId));
    return census?.isActive;
  };

  const addCensus = (census) =>
    CensusService.add(census).then((newCensus) => {
      setCensuses((oldState) => [...oldState, newCensus]);
    });

  const closeCensus = (censusId) =>
    CensusService.closeCensus(censusId).then((r) => {
      CensusService.get().then(setCensuses);
    });

  const checkIfActiveCensusExists = () => censuses?.some((census) => census.isActive);

  const getCensusInventories = useCallback(
    (censusId, queryFilterParams) =>
      CensusService.getCensusInventories(censusId, queryFilterParams).then((response) => {
        setCensusInventories(response);
        return response;
      }),
    []
  );

  const getCensusProgress = useCallback(
    (censusId) =>
      CensusService.getCensusProgress(censusId).then((response) => {
        setProgress(response);
      }),
    []
  );

  const exportData = (exportDataInfo) => {
    return CensusService.exportData(exportDataInfo);
  };

  const getNonCensusedInventory = useCallback(
    (censusId) => CensusService.getNonCensusedInventory(censusId).then(setNotInCensusInventories),
    []
  );

  const updateCensus = (id, census) =>
    CensusService.update(id, census).then(() => {
      getCensusInventories(census.id);
      getCensusProgress(census.id);
    });

  const updateInventoryCensus = (data) => InventoryCensusService.updateDetails(data);

  return (
    <CensusContext.Provider
      value={{
        addCensus,
        getCensuses,
        updateCensus,
        getCensusInventories,
        getNonCensusedInventory,
        updateInventoryCensus,
        closeCensus,
        isActiveCensus,
        checkIfActiveCensusExists,
        getCensusProgress,
        exportData,
        progress,
        notInCensusInventories,
        censusInventories,
        censuses,
        activeCensus,
      }}
    >
      {children}
    </CensusContext.Provider>
  );
}
