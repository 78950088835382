import React from "react";
import { NavLink } from "react-router-dom";
import { EXPORT, SETTINGS, INDEX, CENSUS, HISTORY } from "router/router.config";
import { LogoutButton, ProtectedView } from "domain/Authentication";
import { ExportIcon, HistoryIcon, HomeIcon, PenIcon, SettingsIcon } from "assets";
import clsx from "clsx";

import "./user-navigation.scss";

const protectedItems = [
  {
    label: "Home",
    icon: HomeIcon,
    link: INDEX,
  },
  {
    label: "Istorija",
    icon: HistoryIcon,
    link: HISTORY
  },
  {
    label: "Popis",
    icon: PenIcon,
    link: CENSUS,
  },
  {
    label: "Export",
    icon: ExportIcon,
    link: EXPORT,
  },
  {
    label: "Podešavanja",
    icon: SettingsIcon,
    link: SETTINGS,
  },
];

export const UserNavigation = () => {
  return (
    <div className="user__navigation" onClick={(e) => e.stopPropagation()}>
      <ul className="user__link-list">
        {protectedItems.map((item) => (
          <ProtectedView key={item.label}>
            <li className="user__list-item">
              <NavLink to={item.link} className={({ isActive }) => clsx("user__link", { active: isActive })}>
                <item.icon className="user__icon" />
                <span className="user__label">{item.label}</span>
              </NavLink>
            </li>
          </ProtectedView>
        ))}
        <li className="user__list-item">
          <LogoutButton className="user__link user__link--logout" title="Odjavi se" />
        </li>
      </ul>
    </div>
  );
};
