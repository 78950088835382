import { api } from "../../../shared";

const url = "/api/workspace";

async function getWorkspaceEmployees(params) {
  return api
    .inventar({
      method: "get",
      url: `${url}/employees`,
      params,
    })
    .then((response) => response.data);
}

export const EmployeeService = {
  getWorkspaceEmployees,
};
