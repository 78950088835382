import { api } from "../../../shared";

const url = "/api/census";

async function get() {
  return api
    .inventar({
      method: "get",
      url,
    })
    .then((response) => response.data);
}

async function getCensusInventories(censusId, queryFilterParams) {
  return api
    .inventar({
      method: "get",
      url: `/api/inventory-census/inventories/${censusId}`,
      params: queryFilterParams,
    })
    .then((response) => response.data);
}

async function getNonCensusedInventory(censusId) {
  return api
    .inventar({
      method: "get",
      url: `/api/inventory-census/not-in-census/${censusId}`,
    })
    .then((response) => response.data);
}

async function getCensusProgress(censusId) {
  return api
    .inventar({
      method: "get",
      url: `/api/inventory-census/progress/${censusId}`,
    })
    .then((response) => response.data);
}

async function add(census) {
  return api
    .inventar({
      method: "post",
      url,
      data: census,
    })
    .then((response) => response.data);
}

async function update(id, updateItem) {
  const { description, isActive } = updateItem;
  const data = {
    isActive: description,
    description: isActive,
  };

  return api
    .inventar({
      method: "put",
      url: `${url}/${id}`,
      data,
    })
    .then((response) => response.data);
}

async function closeCensus(id) {
  return api
    .inventar({
      method: "put",
      url: `${url}/close/${id}`,
      data: false,
    })
    .then((response) => response.data);
}

async function exportData(exportDataInfo) {
  try {
    const response = await api.inventar({
      method: "post",
      url: `/api/inventory-census/export/${exportDataInfo.exportFormat}`,
      data: exportDataInfo.data,
      responseType: "blob",
    });
    return response.data;
  } catch (error) {
    console.error("There was an error exporting the data:", error);
    throw error;
  }
}

export const CensusService = {
  add,
  get,
  update,
  getCensusInventories,
  getCensusProgress,
  closeCensus,
  getNonCensusedInventory,
  exportData,
};
