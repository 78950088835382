import React from "react";
import { GoogleLogin } from "@react-oauth/google";

import { useAuthentication } from "../../context";

import "./login-button.scss";
export const LoginButton = () => {
  const { signIn } = useAuthentication();

  return (
    <GoogleLogin
      onSuccess={signIn}
      onError={() => {
        alert("Greška pri logovanju na Google nalog!");
      }}
      useOneTap
      auto_select
    />
  );
};
