import { PlusIcon } from "assets";
import { useSmallInventoryValueContext } from "domain/SmallInventoryValue";
import "./small-inventory-value-settings.scss";
import { CloseIcon } from "assets";
import { useCallback } from "react";
import { Button, SqueezeBox } from "shared";

export const SmallInventoryValueSettings = ({ create, update, archive }) => {
  const { latestSmallInventoryValue, smallInventoryValues, setSelectedSmallInventoryValue } =
    useSmallInventoryValueContext();

  const ValueInEffect = useCallback(() => {
    if (!smallInventoryValues.length) {
      return (
        <div>
          <div className="small-inventory-value-settings__warning">Trenutno nema naznake za sitan inventar</div>
        </div>
      );
    }
    return (
      <div>
        <div>
          <span className="small-inventory-value-settings__description">
            Najnovija vrednost do koje se vodi naznaka za sitan inventar:{" "}
          </span>
          <span className="small-inventory-value-settings__value">{latestSmallInventoryValue.value}</span>
        </div>

        <div>
          <span className="small-inventory-value-settings__description">
            Najnoviji datum od kojeg se primenjuje ova vrednost:{" "}
          </span>
          <span className="small-inventory-value-settings__value">
            {new Date(latestSmallInventoryValue.effectiveDate).toLocaleDateString("en-GB")}
          </span>
        </div>
      </div>
    );
  }, [latestSmallInventoryValue, smallInventoryValues]);

  const Values = useCallback(() => {
    if (!smallInventoryValues.length) {
      return <div className="small-inventory-value-settings__no-previous-value">Trenutno ne postoje naznake</div>;
    }
    return smallInventoryValues.map((value) => {
      return (
        <div className="small-inventory-value-settings__previous-value" key={value.id}>
          <div>
            Datum: {new Date(value.effectiveDate).toLocaleDateString("en-GB")}
            &nbsp; Vrednost: {value.value}
          </div>
          <div className="small-inventory-value-settings__previous-value-actions">
            <Button
              buttonText={"Promeni"}
              buttonColor={"blue"}
              event={() => {
                update();
                setSelectedSmallInventoryValue({ ...value });
              }}
            />
            <div className="small-inventory-value-settings__delete">
              <Button
                buttonIcon={<CloseIcon className="small-inventory-value-settings__previous-value-delete" />}
                event={() => {
                  archive();
                  setSelectedSmallInventoryValue({ ...value });
                }}
              />
            </div>
          </div>
        </div>
      );
    });
  }, [smallInventoryValues, setSelectedSmallInventoryValue, update, archive]);

  return (
    <div className="settings__content small-inventory-value-settings">
      <h2 className="heading">Vrednost osnovnih sredstava i inventara</h2>
      <SqueezeBox>
        <div>Sve vrednosti</div>
        <div className="small-inventory-value-settings__previous-values-body">
          <Values />
        </div>
      </SqueezeBox>
      <ValueInEffect />

      <Button
        buttonText={"Dodaj novu vrednost"}
        buttonColor={"blue"}
        buttonIcon={<PlusIcon className="btn__plus-icon" />}
        event={create}
      />
    </div>
  );
};
