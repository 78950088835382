import React, { useState, useEffect, useMemo } from "react";

import { useInventoryContext } from "../../../context";

import { ItemsSelectList } from "../../../components";
import { SearchBar, Button } from "shared";

import { useModalContext } from "../../../../../shared/components/Modal/context/modal.context";

import { ConfigurationChangesForm } from "../configuration-changes-form/configuration-changes-form";

import "./create-new-inventory-part.form.scss";
export const CreateNewInventoryPartForm = ({ onSubmit, onCancel, inventory, isBtnLoading }) => {
  const inventoryId = inventory.id;
  const inventoryLabel = inventory.label;
  const [newInventoryParts, setNewInventoryParts] = useState([]);
  const [checkboxSelectedCount, setCheckboxSelectedCount] = useState(0);

  const { parts, getParts, validateAllItems, getParentById, getAllItems } = useInventoryContext();

  const { createModal } = useModalContext();

  const [items, setItems] = useState(undefined);

  const {
    element: CreateNewInventoryPartModal,
    open,
    close,
  } = useMemo(() => createModal({ large: true }), [createModal]);

  useEffect(() => {
    getParts(inventoryId);
  }, [getParts, inventoryId]);

  const onSearchQueryChange = (value) => getParts(inventoryId, value);

  const removeItem = (item) => setNewInventoryParts((parts) => parts.filter((part) => part.itemId !== item.itemId));

  const addItem = (value) => setNewInventoryParts((prev) => [...prev, value]);

  useEffect(() => {
    getAllItems(newInventoryParts)
      .then((data) => {
        setItems(data);
      })
      .catch((err) => {
        alert(err);
      });
  }, [newInventoryParts, getAllItems]);

  const onConfirm = (e) => {
    e.preventDefault();
    onSubmit({ inventory: { ...inventory }, inventoryPart: items });
  };

  const openModal = async (e) => {
    e.preventDefault();
    if (!newInventoryParts.length) return alert("Morate odabrati deo za dodavanje!");
    if (await getParentById(inventoryId))
      return alert("Trenutno nije podržano dodavanje delova u predmet koji je deo drugog predmeta.");

    const parts = items.filter((item) => item.parts.length);

    if (parts.length) {
      return alert(`Trenutno nije podržano dodavanje predmeta koji sadrži delove: ${parts.map((part) => part.label)}`);
    }

    validateAllItems(inventoryId, items)
      .then(open)
      .catch((error) => {
        alert(error.message);
      });
  };

  const onCheckboxSelect = (number) => setCheckboxSelectedCount(number);

  return (
    <>
      <div>
        <div className="create-inventory-item">
          <h2 className="create-inventory-item__title">
            <span>Dodavanje delova na predmet</span>
            <br />
            {inventoryLabel}
          </h2>
          <div className="create-inventory-item__search_section">
            <div className="create-inventory-item__search">
              <SearchBar
                onChange={(value) => onSearchQueryChange(value)}
                placeholder="Pretraži"
                autoComplete="off"
                spellCheck="false"
                id="searchPart"
              />
            </div>
          </div>
          <ItemsSelectList
            data={parts}
            addItem={addItem}
            removeItem={removeItem}
            checkboxSelectedCount={onCheckboxSelect}
          />
        </div>
        <div className="create-inventory-item__controls">
          <Button buttonText={"Otkaži"} buttonColor={"gray"} event={onCancel} />
          <Button
            type="submit"
            isDisabled={!checkboxSelectedCount}
            buttonText={"Dodaj"}
            buttonColor={"green"}
            event={openModal}
            spinnerColor={"light"}
            isLoading={isBtnLoading}
          />
        </div>
      </div>
      <CreateNewInventoryPartModal>
        <ConfigurationChangesForm
          onSubmit={onConfirm}
          validation
          onCancel={close}
          data={{ inventory: inventory, inventoryParts: items }}
        />
      </CreateNewInventoryPartModal>
    </>
  );
};
