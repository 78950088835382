import { MarkIcon, CloseIcon } from "assets";

import "./settings-content-list.scss";
import { Button } from "shared";
export const SettingsContentList = ({
  items,
  itemTitle,
  onDelete,
  hasDelete,
  firstItem,
  deleteMessage,
  isBtnLoading,
}) => {
  const confirmDelete = (id) => {
    if (window.confirm(deleteMessage)) {
      onDelete(id);
    }
  };

  return (
    <div className="list-info">
      <div className="list-info__title">{itemTitle}</div>

      <ul className="list-info__list">
        {items.map((item) => (
          <li className="list-info__list-item" key={item.id}>
            <span className="list-info__name">{item.name}</span>
            {item.name === firstItem && <MarkIcon className="list-info__vector" />}
            {hasDelete && (
              <Button
                buttonIcon={<CloseIcon className="list-info__delete-icon" />}
                event={() => confirmDelete(item.id)}
                spinnerColor={"dark"}
                isLoading={isBtnLoading}
              />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SettingsContentList;
