import React, { useContext, useEffect, useState } from "react";

import { EmployeeService } from "../services";
import { usePaginatedData } from "shared/hooks/usePaginatedData";

const EmployeesContext = React.createContext();

export function useEmployeesContext() {
  return useContext(EmployeesContext);
}

export function EmployeesProvider({ children }) {
  const [employees, setEmployees] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { limit, currentPage, resetPage } = usePaginatedData();

  useEffect(() => {
    EmployeeService.getWorkspaceEmployees({ search: searchTerm, page: currentPage, limit }).then((data) => {
      setEmployees(data);
    });
  }, [searchTerm, currentPage, limit]);

  const handleEmployeeSearch = (event) => {
    resetPage();
    setSearchTerm(event);
  };

  return <EmployeesContext.Provider value={{ employees, handleEmployeeSearch }}>{children}</EmployeesContext.Provider>;
}
