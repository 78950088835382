import React, { useState, useContext, useCallback, useEffect } from "react";
import { useLocalStorage } from "../../App/hooks/useLocalStorage";
import { useCategoriesContext } from "domain/Category";
import { useCompaniesContext } from "domain/Company";
import { useSmallInventoryValueContext } from "domain/SmallInventoryValue";
import { InventoryService } from "../../Inventory/services";

const ExportContext = React.createContext();
const DEFAULT_ITEMS_PER_VIEW = 100;
const ITEMS_PER_VIEW = "itemsPerView";

export function useExportContext() {
  return useContext(ExportContext);
}

export function ExportProvider({ children }) {
  const { categories } = useCategoriesContext();
  const { companies } = useCompaniesContext();
  const { smallInventoryValues } = useSmallInventoryValueContext();
  const [applyExportFilters, setApplyExportFilters] = useState({});
  const [pendingExportFilters, setPendingExportFilters] = useState({});
  const [exportItems, setExportItems] = useState([]);

  const saveExportFilter = useCallback(
    (filter) => {
      setPendingExportFilters((existing) => {
        if (filter.values.length === 0) return existing;

        return {
          ...existing,
          [filter.name]: filter.values.join(","),
        };
      });
    },
    [setPendingExportFilters]
  );

  const removeExportFilter = useCallback(
    (filterName) => {
      setPendingExportFilters((existing) => {
        if (Object.keys(existing).length === 0 && typeof existing === "object" && existing != null) return {};

        const cleanedFilters = { ...existing };
        delete cleanedFilters[filterName];
        return cleanedFilters;
      });
    },
    [setPendingExportFilters]
  );

  const applyPendingExportFilters = useCallback(() => {
    setApplyExportFilters(pendingExportFilters);
  }, [pendingExportFilters]);

  const clearFilters = () => {
    setApplyExportFilters({});
    setPendingExportFilters({});
    setExportItems([]);
  };

  useEffect(() => {
    if (
      !categories.length ||
      !companies.length ||
      !smallInventoryValues.length ||
      !Object.keys(applyExportFilters).length
    )
      return;
    const params = { ...applyExportFilters };
    InventoryService.get(params).then((data) => {
      setExportItems(
        data.map((item) => {
          const { companyId, categoryId, ownerId, purchaseDate, ...other } = item;
          const company = companies.find((company) => company.id === companyId);
          const category = categories.find((category) => category.id === categoryId);
          const owner = companies.find((owner) => owner.id === ownerId);

          return {
            ...other,
            purchaseDate: new Date(purchaseDate),
            company,
            category,
            owner,
            parts: item.parts.map((part) => {
              return {
                ...part,
                category: categories.find((category) => category.id === part.categoryId),
                company: companies.find((company) => company.id === part.companyId),
                owner: companies.find((owner) => owner.id === part.ownerId),
              };
            }),
          };
        })
      );
    });
  }, [applyExportFilters, companies, categories, smallInventoryValues]);

  const [itemsPerView, setItemsPerView] = useLocalStorage(ITEMS_PER_VIEW, DEFAULT_ITEMS_PER_VIEW);

  return (
    <ExportContext.Provider
      value={{
        applyExportFilters,
        pendingExportFilters,
        removeExportFilter,
        saveExportFilter,
        itemsPerView,
        setItemsPerView,
        applyPendingExportFilters,
        exportItems,
        clearFilters,
      }}
    >
      {children}
    </ExportContext.Provider>
  );
}
