import { api } from "../../../shared";

const url = "/api/categories";

async function get() {
  return api
    .inventar({
      method: "get",
      url,
    })
    .then((response) => response.data);
}

async function add(category) {
  return api
    .inventar({
      method: "post",
      url,
      data: category,
    })
    .then((response) => response.data);
}

async function remove(categoryId) {
  return api.inventar({
    method: "delete",
    url: `${url}/${categoryId}`,
  });
}

export const CategoryService = {
  get,
  add,
  remove,
};
