import React, { useCallback, useEffect, useState } from "react";

import { Button, TextInput } from "shared";
import { useCensusContext } from "../../../../Census";
import DatePicker from "react-datepicker";
import { common } from "../../../../../shared/constants/constants";
import moment from "moment";

export const CreateCensusForm = ({ onSubmit, onCancel, isBtnLoading }) => {
  const [newCensus, setNewCensus] = useState({ year: "", description: "", isActive: false, startDate: "" });
  const [isValid, setIsValid] = useState(false);
  const [isUnique, setIsUnique] = useState(true);
  const { censuses, checkIfActiveCensusExists } = useCensusContext();

  const onInputChange = (event) => {
    const { name: key, value } = event.target;
    setNewCensus((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const onCheckboxChange = (event) => {
    const { checked, name } = event.target;
    setNewCensus((prev) => ({ ...prev, [name]: checked }));
  };

  const onStartDateChange = useCallback((startDate) => {
    setNewCensus((oldState) => ({ ...oldState, startDate }));
  }, []);

  useEffect(() => {
    setIsValid(newCensus.year.toString().length === 4);
    const isUniqueStartDate = (obj) => moment(obj.startDate).isSame(moment(newCensus.startDate), "day");

    const isUniqueYear = (obj) => {
      return obj.year === newCensus.year;
    };
    setIsUnique(!censuses.some(isUniqueYear) && !censuses.some(isUniqueStartDate));
  }, [newCensus, censuses]);

  const confirm = () => {
    if (!isValid) return alert("Popunite sva ponuđena polja, vrednost za polje 'Godina' mora imati 4 cifre.");
    if (!isUnique) return alert("Početni datum popisa i godina popisa moraju biti jedinstveni.");
    onSubmit(newCensus);
  };

  return (
    <>
      <div className="create-company">
        <h2 className="create-company__title">Kreiranje novog popisa</h2>
        <label className="create-small-inventory-value__label">Početak popisa</label>
        <DatePicker
          dateFormat={common.PREFERRED_DATE_FORMAT}
          className="create-small-inventory-value__input-field create-small-inventory-value__date-field"
          onChange={onStartDateChange}
          selected={newCensus.startDate}
          defaultValue={new Date()}
        />

        <TextInput
          name="year"
          onChange={onInputChange}
          autoComplete="off"
          placeholder="Godina popisa"
          value={newCensus.year || ""}
          maxLength={4}
        />
        <TextInput
          name="description"
          onChange={onInputChange}
          autoComplete="off"
          value={newCensus.description || ""}
          placeholder="Opis"
        />
        <TextInput
          disabled={checkIfActiveCensusExists()}
          name="isActive"
          type="checkbox"
          onChange={onCheckboxChange}
          value={newCensus.isActive}
          label="Aktivan popis"
        />
      </div>
      <div className="create-company__controls">
        <Button
          buttonText={"Potvrdi"}
          buttonColor={"red"}
          event={confirm}
          spinnerColor={"light"}
          isLoading={isBtnLoading}
        />
        <Button buttonText={"Otkaži"} buttonColor={"gray"} event={onCancel} />
      </div>
    </>
  );
};
