import { historyExtraTypeMap, partChangeLabels, partCreateLabels, actionTypeStrings } from "shared/constants/constants";
import { HistoryExtraInfoItem } from "../history-extra-info-item/history-extra-info-item";
import { useMemo } from "react";

const getHistoryExtraLabels = (historyKey, actionType, labelMappings) => {
  const mapping = labelMappings[historyKey];
  if (!mapping) {
    return "";
  }

  return mapping[actionType] || "";
};

export const HistoryExtraInfo = ({ historyData, historyAction, stateBefore, stateAfter }) => {
  const labelMappings = useMemo(() => {
    const mapping = {};
    const actionCreated = actionTypeStrings.created;
    const actionUpdated = actionTypeStrings.updated;

    for (const [key] of historyExtraTypeMap) {
      mapping[historyExtraTypeMap.get(key)] = {
        [actionCreated]: partCreateLabels.get(key),
        [actionUpdated]: partChangeLabels.get(key),
      };
    }

    return mapping;
  }, []);

  return Object.keys(historyData)
    .filter((historyKeyValue) => {
      return (
        typeof historyData[historyKeyValue] !== "object" &&
        historyData[historyKeyValue] !== null &&
        historyKeyValue !== "id"
      );
    })
    .map((historyKeyValue) => (
      <HistoryExtraInfoItem
        key={historyKeyValue}
        label={getHistoryExtraLabels(historyKeyValue, historyAction, labelMappings)}
        extraBefore={stateBefore[historyKeyValue]}
        extraAfter={stateAfter[historyKeyValue]}
        type={historyKeyValue === historyExtraTypeMap.get("TYPE")}
        isDate={historyKeyValue === historyExtraTypeMap.get("PURCHASE_DATE")}
      />
    ));
};
