import React, { useState, useEffect, useCallback } from "react";

import { useFormik, validateYupSchema, yupToFormErrors } from "formik";
import { changeOwnershipDefault, changeOwnershipWs } from "../item-form/item-form-schema";

import { PRICE_FIELD_MAX_LENGTH } from "environment";
import { workStationShortName } from "domain/Inventory/constants";
import { useCompaniesContext } from "domain/Company";

import { Dropdown, Button, TextInput } from "shared";

import "./change-ownership.form.scss";

export const ChangeOwnershipForm = ({ inventory, onSubmit, onCancel, isBtnLoading, handleBlur, touched }) => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [newOwnership, setNewOwnership] = useState();
  const [newPurchasePrice, setNewPurchasePrice] = useState(inventory.purchasePrice);
  const { companies } = useCompaniesContext();

  const { setValues, errors, values, setErrors } = useFormik({
    initialValues: { newPurchasePrice: newPurchasePrice, newOwnership: newOwnership },
  });

  const isWorkStation = () => inventory.category.shortname === workStationShortName;

  const validateSchema = isWorkStation() ? changeOwnershipWs : changeOwnershipDefault;

  useEffect(() => {
    const checkIsFormValid = async () => {
      try {
        await validateYupSchema(values, validateSchema, false);
        setIsFormValid(true);
      } catch (errors) {
        setIsFormValid(false);
        setErrors(yupToFormErrors(errors));
      }
    };

    checkIsFormValid();
  }, [values, validateSchema, errors, setErrors]);

  const isFieldDirty = useCallback(
    (field, type) => {
      const dropDownType = type === "dropdown";
      const fieldErrorMessage = !dropDownType ? errors[field] : errors[field]?.name;
      return fieldErrorMessage;
    },
    [errors]
  );

  const changeOwnershipOptions = () => {
    return companies.filter((company) => company.id !== inventory.owner.id);
  };

  const onConfirm = () => {
    onSubmit(newOwnership, newPurchasePrice);
  };

  const onDropdownChange = useCallback(
    (value) => {
      setValues((oldData) => ({ ...oldData, newOwnership: value[0] }));
      setNewOwnership(value[0]);
    },
    [setValues]
  );

  const onPriceChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      setValues((oldData) => ({ ...oldData, [name]: value }));
      setNewPurchasePrice(value);
    },
    [setValues]
  );

  return (
    <div className="change-ownership-form">
      <h2 className="change-ownership__title">
        <span>Promena vlasnika predmeta</span>
        <br />
        {inventory.label}
      </h2>
      <div className="change-onwership__section">
        <label>
          Vlasnik<span className="text-input__required">*</span>
        </label>
        <Dropdown
          choices={changeOwnershipOptions}
          onChange={onDropdownChange}
          isMultipleChoice={false}
          allSelectedLabel="Odaberite novog vlasnika"
          dropdownError={isFieldDirty("newOwnership", "dropdown")}
          touched={touched}
          handleBlur={handleBlur}
          required
        />
        {!isWorkStation() && (
          <TextInput
            type="number"
            onChange={onPriceChange}
            name="newPurchasePrice"
            label="Nova nabavna cena"
            inputError={isFieldDirty("newPurchasePrice", "input")}
            maxLength={PRICE_FIELD_MAX_LENGTH}
            value={newPurchasePrice}
            onBlur={handleBlur}
            hint={
              "Proverite da li ste dobro uneli novu nabavnu cenu nakon promene vlasnika. Nova nabavna cena treba da bude umanjena za iznos amortizacije."
            }
            required
            step=".01"
          />
        )}
      </div>
      <div className="change-ownership__controls">
        <div className="change-ownership__buttons">
          <Button buttonText={"Otkaži"} buttonColor={"gray"} event={onCancel} />
          <Button
            type="submit"
            isDisabled={!isFormValid}
            buttonText={"Promeni"}
            buttonColor={"green"}
            event={onConfirm}
            spinnerColor={"light"}
            isLoading={isBtnLoading}
          />
        </div>
      </div>
    </div>
  );
};
