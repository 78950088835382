import React, { useEffect } from "react";
import { useCensusContext } from "domain/Census";

import { formatDistanceToNowStrict } from "date-fns";
import { srLatn } from "date-fns/locale/sr-Latn";
import { ProgressBar } from "shared";

import "./census-indicator.scss";

export const CensusIndicator = () => {
  const { activeCensus, getCensusProgress, progress } = useCensusContext();

  useEffect(() => {
    if (!activeCensus) return;
    getCensusProgress(activeCensus.id);
  }, [activeCensus, getCensusProgress]);

  if (!activeCensus || !progress) return null;

  const distanceToEndDateSerbian = formatDistanceToNowStrict(new Date(activeCensus.endDate), { locale: srLatn });

  const { mainProgress } = progress;

  return (
    <div className="census-indicator">
      <ProgressBar progress={(mainProgress.completed / mainProgress.total) * 100} />
      <span className="date-distance">
        Popis traje još&nbsp;
        {distanceToEndDateSerbian}
      </span>
    </div>
  );
};
