import "./item-display-history.scss";
import { useInventoryContext } from "../../context";
import React, { useEffect, useState } from "react";
import { HistoryEvent } from "domain/History/components";
import { InputSwitch } from "shared";

const switchDescription = "Prikaži dodatne informacije za svaku arhiviranu akciju";

export const ItemDisplayHistory = ({ item, historyDates }) => {
  const { getHistoryById } = useInventoryContext();
  const [history, setHistory] = useState([]);
  const [showExtraInfo, setShowExtraInfo] = useState(false);

  useEffect(() => {
    getHistoryById(item.id, historyDates)
      .then((data) => setHistory(data))
      .catch(console.error);
  }, [getHistoryById, item.id, historyDates, item]);

  const toggleExtraInfoHandler = () => {
    setShowExtraInfo(!showExtraInfo);
  };

  return (
    <div className="container">
      <div className="row">
        <InputSwitch
          className="input-switch__container--history"
          name="showExtraInfo"
          hideLabels={true}
          description={switchDescription}
          onChange={toggleExtraInfoHandler}
          small
        ></InputSwitch>
      </div>
      <div className="row row--column">
        {history.map((historyElement) => (
          <HistoryEvent key={historyElement.id} event={historyElement} showAllExtraInfo={showExtraInfo}></HistoryEvent>
        ))}
      </div>
    </div>
  );
};
