import React, { useContext, useEffect, useState } from "react";

import { CompanyService } from "../services";

const CompaniesContext = React.createContext();

export function useCompaniesContext() {
  return useContext(CompaniesContext);
}

export function CompaniesProvider({ children }) {
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    CompanyService.get().then(setCompanies);
  }, []);

  const updateCompany = (id, company) => CompanyService.update(id, company);

  const addCompany = (company) =>
    CompanyService.add(company).then((newCompany) => {
      setCompanies((oldState) => [...oldState, newCompany]);
    });

  const deleteCompany = (id) =>
    CompanyService.remove(id).then(() => setCompanies((prev) => prev.filter((company) => company.id !== id)));

  return (
    <CompaniesContext.Provider value={{ addCompany, updateCompany, deleteCompany, companies }}>
      {children}
    </CompaniesContext.Provider>
  );
}
