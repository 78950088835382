import React, { useState } from "react";

import { ScrollPosition } from "shared";
import { HistoryTable } from "domain/History";
import { HistoryEvent } from "domain/History";
import { HistoryFiltersGroup, useHistoryContext } from "domain/History";

import "./history.page.scss";

export const HistoryPage = () => {
  const { histories } = useHistoryContext();
  const [focusedItem, setFocusedItem] = useState();

  const onItemTableRowClick = (row) => {
    const item = histories.find((historyItem) => historyItem.id === row.id);
    setFocusedItem(item);
  };

  const onCloseHistoryEvent = () => {
    setFocusedItem(undefined);
  };

  return (
    <>
      <div className="home-header">
        <div className="home-header_left">
          <HistoryFiltersGroup />
        </div>
      </div>
      <div className="export-items-table-container">
        <ScrollPosition uniqueId="export-dashboard-page">
          {histories ? <HistoryTable data={histories} onRowClick={onItemTableRowClick} /> : null}
        </ScrollPosition>
        {focusedItem && (
          <HistoryEvent
            key={focusedItem.id}
            event={focusedItem}
            showAllExtraInfo
            closeIcon
            closeHistoryEvent={onCloseHistoryEvent}
          />
        )}
      </div>
    </>
  );
};
