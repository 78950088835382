import { useHistoryContext } from "../context";

export function useItemHistoryFilters() {
  const { saveHistoryFilter, removeHistoryFilter } = useHistoryContext();

  const handleFilterBySearch = (term, filterName = "searchTerm") => {
    if (term === "") {
      removeHistoryFilter(filterName);
    } else {
      saveHistoryFilter({
        name: filterName,
        values: [term],
      });
    }
  };

  const handleFilterByProperty = (filterItems, filterName, property) => {
    if (!Array.isArray(filterItems) || !filterItems.length) {
      removeHistoryFilter(filterName);
    } else {
      const filterValues = [];
      filterItems.forEach((filter) => {
        filterValues.push(filter[property]);
      });

      saveHistoryFilter({
        name: filterName,
        values: filterValues,
      });
    }
  };

  return {
    onSearchQueryChange: (term) => handleFilterBySearch(term),
    onHistoryActionsFilterChange: (historyActions) => handleFilterByProperty(historyActions, "actionNames", "value"),
    onActionCreatorFilterChange: (employees) => handleFilterByProperty(employees, "actionOwnerEmails", "email"),
    onHistoryDateFilterChange: (filter) => handleFilterByProperty([filter], filter.name, "value"),
  };
}
