import React from "react";
import clsx from "clsx";

import "./item-type-label.scss";

export const ItemTypeLabel = ({ type }) => {
  const typeClass = {
    smallInventory: "SI",
    fixedAsset: "OS",
    immaterialAsset: "IM",
  };

  return <span className={clsx("item-type " + type)}>{typeClass[type]}</span>;
};
