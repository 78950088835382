import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAuthentication } from "../../context";
import { LoginButton } from "../../components";

import { VegaLogo } from "assets";
import "./login.page.scss";

export const LoginPage = () => {
  const { user } = useAuthentication();
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (user) navigate(state || "/");
  }, [user, navigate, state]);

  return (
    <div className="login">
      <div className="login__content">
        <div className="login__image">
          <span className="login__copyright"> &copy; VegaIT 2022</span>
          <span className="login__text-logo">All rights reserved.</span>
        </div>
      </div>
      <div className="login__wrapper">
        <div className="login__container">
          <VegaLogo />
          <h1 className="login__heading">
            Dobrodošli u <span className="login__app-name">Inventar App</span>
          </h1>
          <span className="login__text">Ulogujte se Google nalogom</span>
          <LoginButton className="login__login-button" title="Sign in with Google" />
          <span className="login__description">
            Ukoliko imate poteškoće pri logovanju, uverite se da se logujete sa Vega IT naloga
          </span>
        </div>
      </div>
    </div>
  );
};
