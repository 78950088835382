import React from "react";
import QRCode from "qrcode.react";
import clsx from "clsx";
import "./sticker-preview.scss";

export const StickerPreview = React.forwardRef(
  ({ title, subtitle, description, footer = [], url, reversed, badge }, ref) => {
    return (
      <div className="sticker" ref={ref}>
        <div className={clsx("sticker__content", { reversed: url && reversed })}>
          {badge}
          {url ? (
            <div className="sticker__qr-content">
              <QRCode id={url} value={url} size={65} level={"M"} renderAs="svg" className="code__qrcode" />
            </div>
          ) : null}
          <div className="sticker__text">
            <div className="sticker__info">
              <div className="sticker__title">{title}</div>
              {subtitle && <div className="sticker__subtitle">{subtitle}</div>}
              {description && <div className="sticker__description">{description}</div>}
            </div>
            {footer.length > 0 ? (
              <div className="sticker__footer">
                {footer.map((item, idx) => (
                  <div key={`${item}-${idx}`}>{item}</div>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
);
