import { useCallback } from "react";
import { useExportContext } from "../context";

export function useItemsExportFilters() {
  const { saveExportFilter, removeExportFilter } = useExportContext();

  const handleExportFilterByProperty = useCallback(
    (filterItems, filterName, property) => {
      if (!Array.isArray(filterItems) || !filterItems.length) {
        removeExportFilter(filterName);
      } else {
        const filterValues = [];
        filterItems.forEach((filter) => {
          filterValues.push(filter[property]);
        });

        saveExportFilter({
          name: filterName,
          values: filterValues,
        });
      }
    },
    [removeExportFilter, saveExportFilter]
  );

  return {
    onTypeFilterChange: (types) => handleExportFilterByProperty(types, "types", "value"),
    onCompanyFilterChange: (companies) => handleExportFilterByProperty(companies, "companyId", "id"),
    onOwnershipFilterChange: (companies) => handleExportFilterByProperty(companies, "ownerId", "id"),
    onFormatFilterChange: useCallback(
      (formats) => handleExportFilterByProperty(formats, "format", "value"),
      [handleExportFilterByProperty]
    ),
    onCategoryValueFilterChange: (categories) => handleExportFilterByProperty(categories, "categoryId", "id"),
    onCheckboxFilterChange: (partsExcluded) => handleExportFilterByProperty([partsExcluded], "partsExcluded", "value"),
  };
}
