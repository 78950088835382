import { api } from "../../../shared";

const url = "/api/parts";

async function getParentById(id) {
  return api
    .inventar({
      method: "get",
      url: `${url}/?parentId=${id}`,
    })
    .then((response) => response.data);
}

async function addParts({ inventoryId, partIds, validateOnly = false }) {
  const data = {
    inventoryId: inventoryId.toString(),
    partIds,
  };

  return api
    .inventar({
      method: "post",
      url: validateOnly ? url + "/validate" : url,
      data,
    })
    .then((response) => response.data)
    .catch((error) => alert(error));
}

async function deletePart(inventoryId, partId) {
  return api.inventar({
    method: "delete",
    url: `${url}/${inventoryId}/${partId}`,
  });
}

export const InventoryPartService = {
  addParts,
  getParentById,
  deletePart,
};
