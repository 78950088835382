import React, { useContext, useEffect, useState, useCallback } from "react";

import { AmortizationTypeService } from "../services";

const AmortizationTypeContext = React.createContext();

export function useAmortizationTypeContext() {
  return useContext(AmortizationTypeContext);
}

export function AmortizationTypeProvider({ children }) {
  const [amortizationTypes, setAmortizationTypes] = useState([]);

  const [applyAmortizationTypeFilter, setApplyAmortizationTypeFilter] = useState({});
  const [pendingAmortizationTypeFilter, setPendingAmortizationTypeFilter] = useState({});

  const applyPendingAmortizationTypeFilters = useCallback(() => {
    setApplyAmortizationTypeFilter(pendingAmortizationTypeFilter);
  }, [pendingAmortizationTypeFilter]);

  const saveAmortizationTypeFilter = useCallback(
    (filter) => {
      setPendingAmortizationTypeFilter((existing) => {
        if (filter.values.length === 0) return existing;

        return {
          ...existing,
          [filter.name]: filter.values.join(","),
        };
      });
    },
    [setPendingAmortizationTypeFilter]
  );

  const removeAmortizationTypeFilter = useCallback(
    (filterName) => {
      setPendingAmortizationTypeFilter((existing) => {
        const cleanedFilters = { ...existing };
        delete cleanedFilters[filterName];
        return cleanedFilters;
      });
    },
    [setPendingAmortizationTypeFilter]
  );

  const clearAmortizationTypeFilters = () => {
    setApplyAmortizationTypeFilter({});
    setPendingAmortizationTypeFilter({});
  };

  useEffect(() => {
    const params = { ...applyAmortizationTypeFilter };
    AmortizationTypeService.get(params).then(setAmortizationTypes);
  }, [applyAmortizationTypeFilter]);

  const addAmortizationType = async (newAmortizationType) => {
    AmortizationTypeService.add(newAmortizationType).then((amortizationType) => {
      setAmortizationTypes((values) => [...values, amortizationType]);
    });
  };

  const deleteAmortizationType = async (id) => {
    AmortizationTypeService.remove(id).then(() =>
      setAmortizationTypes((prev) => prev.filter((amortizationType) => amortizationType.id !== id))
    );
  };
  return (
    <AmortizationTypeContext.Provider
      value={{
        amortizationTypes,
        addAmortizationType,
        deleteAmortizationType,
        applyPendingAmortizationTypeFilters,
        saveAmortizationTypeFilter,
        removeAmortizationTypeFilter,
        clearAmortizationTypeFilters,
      }}
    >
      {children}
    </AmortizationTypeContext.Provider>
  );
}
