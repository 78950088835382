import React, { useCallback, useMemo, useState } from "react";

import { useCategoriesContext, CreateNewCategoryForm } from "domain/Category";
import { useCompaniesContext, CreateNewCompanyForm } from "domain/Company";
import { useAmortizationTypeContext, CreateNewAmortizationTypeForm } from "domain/AmortizationType";
import { ToastMessage, useModalContext } from "shared";
import { PlusIcon, CorrectIcon } from "assets";

import { SettingsContent } from "../../components";
import {
  CreateSmallInventoryForm,
  useSmallInventoryValueContext,
  SmallInventoryValueSettings,
  UpdateSmallInventoryForm,
  ConfirmDeletionSmallInventoryValue,
} from "domain/SmallInventoryValue";

export const SettingsPage = () => {
  const { categories, addCategory, deleteCategory } = useCategoriesContext();
  const { companies, addCompany, deleteCompany } = useCompaniesContext();
  const { amortizationTypes, addAmortizationType, deleteAmortizationType } = useAmortizationTypeContext();
  const { addSmallInventoryValue, updateSmallInventoryValue, archiveSmallInventoryValue } =
    useSmallInventoryValueContext();
  const { createModal } = useModalContext();

  const [createCategoryIsBtnLoading, setCreateCategoryIsBtnLoading] = useState(false);
  const [createCompanyIsBtnLoading, setCreateCompanyIsBtnLoading] = useState(false);
  const [createSmallInventoryIsBtnLoading, setCreateSmallInventoryIsBtnLoading] = useState(false);
  const [updateSmallInventoryIsBtnLoading, setUpdateSmallInventoryIsBtnLoading] = useState(false);
  const [deleteSmallInventoryIsBtnLoading, setDeleteSmallInventoryIsBtnLoading] = useState(false);
  const [deleteCategoryIsBtnLoading, setDeleteCategoryIsBtnLoading] = useState(false);
  const [deleteCompanyIsBtnLoading, setDeleteCompanyIsBtnLoading] = useState(false);
  const [createAmorizationTypeIsBtnLoading, setCreateAmorizationTypeIsBtnLoading] = useState(false);
  const [deleteAmortizationTypeIsBtnLoading, setDeleteAmortizationTypeIsBtnLoading] = useState(false);

  const {
    element: NewCategoryModal,
    open: openCategoryModal,
    close: closeCategoryModal,
  } = useMemo(() => createModal(), [createModal]);

  const {
    element: NewCompanyModal,
    open: openCompanyModal,
    close: closeCompanyModal,
  } = useMemo(() => createModal(), [createModal]);

  const {
    element: NewSmallInventoryValueModal,
    open: openNewSmallInventoryValueModal,
    close: closeNewSmallInventoryValueModal,
  } = useMemo(() => createModal(), [createModal]);

  const {
    element: NewAmortizationTypeModal,
    open: openAmortizationTypeModal,
    close: closeAmortizationTypeModal,
  } = useMemo(() => createModal(), [createModal]);

  const {
    element: UpdateSmallInventoryValueModal,
    open: openUpdateSmallInventoryValueModal,
    close: closeUpdateSmallInventoryValueModal,
  } = useMemo(() => createModal(), [createModal]);

  const {
    element: DeleteSmallInventoryValueModal,
    open: openDeleteSmallInventoryValueModal,
    close: closeDeleteSmallInventoryValueModal,
  } = useMemo(() => createModal(), [createModal]);

  const { element: ToastModal } = useMemo(() => createModal(), [createModal]);

  const createCategory = useCallback(
    (category) => {
      setCreateCategoryIsBtnLoading(true);
      addCategory(category).finally(() => {
        setCreateCategoryIsBtnLoading(false);
        closeCategoryModal();
      });
    },
    [closeCategoryModal, addCategory]
  );

  const createCompany = useCallback(
    (company) => {
      setCreateCompanyIsBtnLoading(true);
      addCompany(company)
        .then(() => setCreateCompanyIsBtnLoading(false))
        .finally(() => closeCompanyModal());
    },
    [closeCompanyModal, addCompany]
  );

  const createSmallInventory = useCallback(
    (smallInventoryValue) => {
      setCreateSmallInventoryIsBtnLoading(true);
      addSmallInventoryValue(smallInventoryValue)
        .then(() => setCreateSmallInventoryIsBtnLoading(false))
        .finally(() => closeNewSmallInventoryValueModal());
    },
    [closeNewSmallInventoryValueModal, addSmallInventoryValue]
  );

  const updateSmallInventory = useCallback(
    (smallInventoryValue) => {
      setUpdateSmallInventoryIsBtnLoading(true);
      updateSmallInventoryValue(smallInventoryValue)
        .then(() => setUpdateSmallInventoryIsBtnLoading(false))
        .finally(() => closeUpdateSmallInventoryValueModal());
    },
    [closeUpdateSmallInventoryValueModal, updateSmallInventoryValue]
  );

  const deleteSmallInventory = useCallback(
    (id) => {
      setDeleteSmallInventoryIsBtnLoading(true);
      archiveSmallInventoryValue(id)
        .then(() => setDeleteSmallInventoryIsBtnLoading(false))
        .finally(() => closeDeleteSmallInventoryValueModal());
    },
    [closeDeleteSmallInventoryValueModal, archiveSmallInventoryValue]
  );

  const onCategoryDelete = useCallback(
    (id) => {
      setDeleteCategoryIsBtnLoading(true);
      deleteCategory(id)
        .then(() => setDeleteCategoryIsBtnLoading(false))
        .finally(() => closeCategoryModal());
    },
    [closeCategoryModal, deleteCategory]
  );

  const onCompanyDelete = useCallback(
    (id) => {
      setDeleteCompanyIsBtnLoading(true);
      deleteCompany(id)
        .then(() => setDeleteCompanyIsBtnLoading(false))
        .finally(() => closeCompanyModal());
    },
    [closeCompanyModal, deleteCompany]
  );
  const createAmortizationType = useCallback(
    (amortizationType) => {
      setCreateAmorizationTypeIsBtnLoading(true);
      addAmortizationType(amortizationType)
        .then(() => setCreateAmorizationTypeIsBtnLoading(false))
        .finally(() => closeAmortizationTypeModal(false));
    },
    [addAmortizationType, closeAmortizationTypeModal]
  );
  const onAmortizationTypeDelete = useCallback(
    (id) => {
      setDeleteAmortizationTypeIsBtnLoading(true);
      deleteAmortizationType(id)
        .then(() => setDeleteAmortizationTypeIsBtnLoading(false))
        .finally(() => closeAmortizationTypeModal());
    },
    [closeAmortizationTypeModal, deleteAmortizationType]
  );

  return (
    <div className="settings">
      <SmallInventoryValueSettings
        create={openNewSmallInventoryValueModal}
        update={openUpdateSmallInventoryValueModal}
        archive={openDeleteSmallInventoryValueModal}
      ></SmallInventoryValueSettings>
      <SettingsContent
        heading="Kategorije inventara"
        description="Kategorije služe za organizaciju inventara po tipu predmeta. Molimo Vas da obratite pažnju prilikom kreiranja nove kategorije kako bi sve informacije bile pravilne."
        items={categories}
        itemTitle="Trenutne kategorije"
        buttonText="Dodaj novu kategoriju"
        buttonColor="blue"
        buttonIcon={<PlusIcon className="btn__plus-icon" />}
        event={openCategoryModal}
        onDelete={onCategoryDelete}
        hasDelete={true}
        deleteMessage="Are you sure you want to delete this category?"
        isBtnLoading={deleteCategoryIsBtnLoading}
      ></SettingsContent>
      <SettingsContent
        heading="Vlasnik inventara"
        description="Vlasnik inventara predstavlja pravnu informaciju koja će se uzeti u obzir pri izvozu inventara za Navigator."
        warning="*Molimo Vas da obratite pažnju na radnje koje izvršavate po pitanju vlasništva, jer svaka greška preuzima pravni kontekst*"
        items={companies}
        itemTitle="Trenutne kompanije"
        buttonText="Dodaj novu kompaniju"
        buttonColor={"red"}
        buttonIcon={<PlusIcon className="btn__plus-icon" />}
        onDelete={onCompanyDelete}
        event={openCompanyModal}
        hasDelete={true}
        deleteMessage="Are you sure you want to delete this company?"
        isBtnLoading={deleteCompanyIsBtnLoading}
      ></SettingsContent>
      <SettingsContent
        heading="Tipovi amortizacije"
        items={amortizationTypes.map((item) => ({
          id: item.id,
          name: `${item.name} ${item.amortizationRate > 0 ? item.amortizationRate + "%" : ""}`,
        }))}
        itemTitle="Trenutni tipovi amortizacije"
        buttonText="Dodaj novi tip amortizacije"
        backgroundColor="#DE3922"
        buttonIcon={<PlusIcon className="btn__plus-icon" />}
        onDelete={onAmortizationTypeDelete}
        open={openAmortizationTypeModal}
        hasDelete={true}
        event={openAmortizationTypeModal}
        buttonColor={"red"}
        isBtnLoading={deleteAmortizationTypeIsBtnLoading}
        deleteMessage="Are you sure you want to delete this amortization type?"
      ></SettingsContent>

      <NewCategoryModal>
        <CreateNewCategoryForm
          onSubmit={createCategory}
          onCancel={closeCategoryModal}
          isBtnLoading={createCategoryIsBtnLoading}
        />
      </NewCategoryModal>
      <NewCompanyModal>
        <CreateNewCompanyForm
          onSubmit={createCompany}
          onCancel={closeCompanyModal}
          isBtnLoading={createCompanyIsBtnLoading}
        />
      </NewCompanyModal>
      <NewSmallInventoryValueModal>
        <CreateSmallInventoryForm
          onSubmit={createSmallInventory}
          isBtnLoading={createSmallInventoryIsBtnLoading}
        ></CreateSmallInventoryForm>
      </NewSmallInventoryValueModal>
      <NewAmortizationTypeModal>
        <CreateNewAmortizationTypeForm
          isBtnLoading={createAmorizationTypeIsBtnLoading}
          onSubmit={createAmortizationType}
          onCancel={closeAmortizationTypeModal}
        />
      </NewAmortizationTypeModal>
      <UpdateSmallInventoryValueModal>
        <UpdateSmallInventoryForm
          onSubmit={updateSmallInventory}
          isBtnLoading={updateSmallInventoryIsBtnLoading}
        ></UpdateSmallInventoryForm>
      </UpdateSmallInventoryValueModal>
      <DeleteSmallInventoryValueModal>
        <ConfirmDeletionSmallInventoryValue
          onConfirm={deleteSmallInventory}
          isBtnLoading={deleteSmallInventoryIsBtnLoading}
        ></ConfirmDeletionSmallInventoryValue>
      </DeleteSmallInventoryValueModal>
      <ToastModal>
        <ToastMessage icon={<CorrectIcon />} message="Uspešno ste dodali novu kategoriju!" />
      </ToastModal>
    </div>
  );
};
