import React, { useState, useContext, useCallback } from "react";
import { sortingOptions, sortingDirectionMap } from "shared/constants/constants";

const SortingContext = React.createContext();

export function useSortingContext() {
  return useContext(SortingContext);
}

const initialValue = {
  name: sortingOptions[0].name,
  key: sortingOptions[0].value,
  direction: sortingDirectionMap.get("DESCEND"),
};

export function SortingProvider({ children }) {
  const [applySorting, setApplySorting] = useState({});
  const [pendingSort, setPendingSort] = useState(initialValue);

  const applySortingHandler = useCallback(() => {
    setApplySorting(pendingSort);
  }, [pendingSort]);

  const clearSorting = () => {
    setApplySorting({});
    setPendingSort(initialValue);
  };

  return (
    <SortingContext.Provider
      value={{
        pendingSort,
        setPendingSort,
        applySortingHandler,
        applySorting,
        clearSorting,
      }}
    >
      {children}
    </SortingContext.Provider>
  );
}
