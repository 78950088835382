import "./history-event.scss";
import React, { useState, useMemo, useEffect } from "react";
import { FaUserCircle } from "react-icons/fa";
import { actionTypeMap, actionTypeStrings } from "shared/constants/constants";
import clsx from "clsx";
import { HistoryExtraInfo } from "../history-extra-info/history-extra-info";
import { CloseIcon } from "assets";
import moment from "moment";

const getDifference = (a, b) =>
  Object.entries(b)
    .filter(([key, val]) => a[key] !== val && key in a)
    .reduce((a, [key, v]) => ({ ...a, [key]: v }), {});

export const HistoryEvent = ({ event, showAllExtraInfo = false, closeIcon = false, closeHistoryEvent }) => {
  const [showExtraInfo, setShowExtraInfo] = useState(false);
  const [actionText, setActionText] = useState("");
  const [historyAction, setHistoryAction] = useState(actionTypeStrings.created);

  const { createdAt, stateBefore, stateAfter, actionOwnerName, actionOwnerEmail, actionOwnerPicture, actionType } =
    event;

  useEffect(() => {
    switch (actionType) {
      case actionTypeMap.get(actionTypeStrings.created):
        const createActionText = (
          <p>
            kreirao/la <strong>{stateAfter.label}</strong> , evidencioni broj: <strong>{stateAfter.itemId}</strong>
          </p>
        );
        setActionText(createActionText);
        break;
      case actionTypeMap.get(actionTypeStrings.updated):
        const updateActionText = (
          <p>
            izmenio/la <strong>{stateBefore.label}</strong> , evidencioni broj: <strong>{stateAfter.itemId}</strong>
          </p>
        );
        setActionText(updateActionText);
        setHistoryAction(actionTypeStrings.updated);
        break;
      case actionTypeMap.get(actionTypeStrings.deleted):
        const deleteActionText = (
          <p>
            obrisao/la <strong>{stateBefore.label}</strong> , evidencioni broj: <strong>{stateBefore.itemId}</strong>
          </p>
        );
        setActionText(deleteActionText);
        setHistoryAction(actionTypeStrings.deleted);
        break;
      case actionTypeMap.get(actionTypeStrings.part_updated):
        const addConfigActionText = (
          <p>
            dodao/la u konfiguraciju <strong>{stateAfter.label}</strong> , evidencioni broj:{" "}
            <strong>{stateAfter.itemId}</strong>
          </p>
        );
        setActionText(addConfigActionText);
        break;
      case actionTypeMap.get(actionTypeStrings.parent_updated):
        const addPartActionText = (
          <p>
            dodao/la deo <strong>{stateAfter.label}</strong> , evidencioni broj: <strong>{stateAfter.itemId}</strong>
          </p>
        );
        setActionText(addPartActionText);
        break;
      default:
        setActionText("");
    }
  }, [actionType, stateAfter.itemId, stateAfter.label, stateBefore.itemId, stateBefore.label]);

  const historyExtraData = useMemo(() => {
    switch (actionType) {
      case actionTypeMap.get(actionTypeStrings.updated):
        return getDifference(stateBefore, stateAfter);
      case actionTypeMap.get(actionTypeStrings.deleted):
        return {};
      default:
        return stateAfter;
    }
  }, [actionType, stateAfter, stateBefore]);

  const toggleExtraInfoHandler = () => {
    setShowExtraInfo(!showExtraInfo);
  };

  return (
    <div className="history-item">
      {closeIcon ? (
        <button className="closeIcon" onClick={closeHistoryEvent}>
          <CloseIcon />
        </button>
      ) : (
        <button
          className={clsx("history-item__toggle", {
            "history-item__toggle--active": showExtraInfo || showAllExtraInfo,
          })}
          onClick={toggleExtraInfoHandler}
        >
          <span className="history-item__chevron"></span>
        </button>
      )}
      <div className="history-item__top">
        <div className="row">
          <div className="icon-column">
            {actionOwnerPicture ? (
              <div
                className="user-avatar"
                style={{
                  backgroundImage: `url("${actionOwnerPicture}")`,
                }}
              />
            ) : (
              <FaUserCircle size={40} color={"#606772"}></FaUserCircle>
            )}
          </div>
          <div className="info-column">
            <div className="info-row">
              <div className="info-column-name">{actionOwnerName + ` (${actionOwnerEmail})`}</div>
            </div>
            <div className="info-row">
              <div className="info-column-text">{actionText}</div>
            </div>
            <div className="info-row">
              <div className="info-column-date">
                {moment(new Date(createdAt)).isValid() && moment(new Date(createdAt)).format("DD/MM/YYYY HH:mm:ss")}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={clsx("history-item__extra", { "history-item__extra--open": showExtraInfo || showAllExtraInfo })}>
        <div className="history-item__extra-info">
          <HistoryExtraInfo
            historyData={historyExtraData}
            historyAction={historyAction}
            stateBefore={stateBefore}
            stateAfter={stateAfter}
          />
        </div>
      </div>
    </div>
  );
};
