import { useRef, useMemo, useState } from "react";
import { PrintElement } from "domain/Print";
import { CloseIcon } from "assets";
import { WIDTH_STICKER_FOR_PRINT, HEIGHT_STICKER_FOR_PRINT } from "environment";

import "./stickers-for-print.scss";
import { Button, InputSwitch } from "shared";
import { LocationGroupSticker } from "./../location-group-sticker/location-group-sticker";

const locationStickerToggleLabel = "Prikaži lokaciju";

/**
 * Number of stickers in group
 * @param {Array} items
 * @returns {Number} Number of stickers
 */
function stickerGroupCount(items) {
  return items?.reduce((acc, item) => {
    const stickerNumber = 1; // Always 1, because sticker number is always based on assigned item. Item is always assigned to one person
    const numberOfItems =
      Object.keys(item.workstation).length > 0 ? item.workstation.parts.length : item.itemsWithoutWorkstation.length;
    return (acc = acc + numberOfItems + stickerNumber);
  }, 0);
}

/**
 * Items by floor conversion
 * @param {Array} items Array of assigned items
 * @returns {Object} - assignedTo, workstation item and other items
 */
function itemsByLocation(items) {
  const data = items.map((item) => {
    const assignedItems = item.assignedItems.flat();

    if (assignedItems.length === 0) return null;

    // workstation and workstation with it's parts
    const { workstation, workstationWithParts } = assignedItems.reduce(
      (acc, item) => {
        if (item.parts && item.parts.length > 0) {
          acc.workstation = item;
          acc.workstationWithParts.push(item.parts.concat(item));
        }

        return acc;
      },
      {
        workstation: {},
        workstationWithParts: [],
      }
    );

    // All other items that are not part of workstation
    const itemsWithoutWorkstation = assignedItems.filter(
      ({ itemId: itemsWithoutWorkstationItemId }) =>
        !workstationWithParts
          .flat()
          .some(({ itemId: workstationItemId }) => workstationItemId === itemsWithoutWorkstationItemId)
    );

    return {
      assignedTo: item.assignedTo,
      workstation,
      itemsWithoutWorkstation,
    };
  });

  return data;
}

export const StickersForPrint = ({ selectedItems, onClose }) => {
  const stickerGroupElement = useRef();
  const [locationStickerPrint, setLocationStickerPrint] = useState(true);

  const onCloseHandler = () => {
    onClose();
  };

  const toggleLocationPrint = () => {
    setLocationStickerPrint((locationSticker) => !locationSticker);
  };

  const groupByLocation = useMemo(() => {
    const groupByLocation = selectedItems
      .map(({ original }) => original)
      .reduce((result, item) => {
        const { assignedTo, location } = item;
        const { city, floor } = location;

        const assignedLocation = !city ? "Working from home" : `${city} - ${floor}`;

        const existingLocation = result.find((loc) => loc.location === assignedLocation);
        if (existingLocation) {
          const existingAssignedTo = existingLocation.items.find((item) => item.assignedTo === assignedTo);
          if (existingAssignedTo) {
            existingAssignedTo.assignedItems.push(item);
          } else {
            existingLocation.items.push({ assignedTo, assignedItems: [item] });
          }
        } else {
          result.push({ location: assignedLocation, items: [{ assignedTo, assignedItems: [item] }] });
        }

        return result;
      }, []);

    return groupByLocation.map((group) => {
      const items = itemsByLocation(group.items);

      return {
        location: group.location,
        items,
      };
    });
  }, [selectedItems]);

  const stickersCount = useMemo(() => {
    const numberOfGroupStickers = groupByLocation.map((group) => {
      return stickerGroupCount(group.items);
    });

    const numberOfGroups = groupByLocation.length > 1 ? groupByLocation.length : 0;

    return numberOfGroupStickers.length ? numberOfGroupStickers.reduce((sum, num) => sum + num, 0) + numberOfGroups : 0;
  }, [groupByLocation]);

  return (
    <div className="stickers__content" onClick={(e) => e.stopPropagation()}>
      <div className="stickers__close">
        <Button buttonIcon={<CloseIcon className="stickers__delete-icon" />} event={onCloseHandler} />
      </div>
      <div ref={stickerGroupElement} className="stickers__list">
        {groupByLocation.map((group) => (
          <LocationGroupSticker
            key={group.location}
            group={group}
            isOnlyOne={groupByLocation.length === 1}
            locationSticker={locationStickerPrint}
          />
        ))}
      </div>
      <div className="stickers__print">
        <div className="stickers__print-location">
          <InputSwitch
            isChecked={locationStickerPrint}
            name="locationStickerToggle"
            onChange={toggleLocationPrint}
            description={locationStickerToggleLabel}
          ></InputSwitch>
        </div>
        <PrintElement
          elementRef={stickerGroupElement}
          width={WIDTH_STICKER_FOR_PRINT}
          height={HEIGHT_STICKER_FOR_PRINT}
          buttonText={`Štampaj ukupno ${stickersCount} nalepnica`}
        />
      </div>
    </div>
  );
};
