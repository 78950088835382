import React, { useCallback, useEffect, useState } from "react";

import { useCategoriesContext } from "domain/Category";
import { StickerPreview } from "domain/Print";

import { Button, TextInput } from "shared";

import "./create-new-category.form.scss";
import { INPUT_FIELD_MAX_LENGTH } from "environment";

export const CreateNewCategoryForm = ({ onSubmit, onCancel, isBtnLoading }) => {
  const [newCategory, setNewCategory] = useState({ name: "", shortname: "", isSerialNumberRequired: false });
  const [isValid, setIsValid] = useState(false);
  const [isUnique, setIsUnique] = useState(true);
  const { categories } = useCategoriesContext();

  const onInputChange = (event) => {
    const { name: key, value } = event.target;
    setNewCategory((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const onCheckboxChange = (event) => {
    const { checked, name } = event.target;
    setNewCategory((prev) => ({ ...prev, [name]: checked }));
  };

  useEffect(() => {
    setIsValid(newCategory.name?.length && newCategory.shortname?.length && newCategory.shortname?.length <= 3);
    const isUniqueName = (obj) => obj.name === newCategory.name;
    const isUniqueShortName = (obj) => obj.shortname === newCategory.shortname;
    setIsUnique(!categories.some(isUniqueName) && !categories.some(isUniqueShortName));
  }, [newCategory, categories]);

  const confirm = useCallback(() => {
    if (!isValid)
      return alert("Popunite sva ponuđena polja, skraćenica kategorije mora biti manja ili jednaka od tri karaktera");
    if (!isUnique) return alert("Naziv kategorije i skracenica kategorije moraju biti jedinstveni");
    onSubmit(newCategory);
  }, [isValid, isUnique, onSubmit, newCategory]);

  return (
    <>
      <div className="create-category">
        <h2 className="create-category__title">Dodavanje nove kategorije</h2>
        <TextInput
          name="name"
          onChange={onInputChange}
          autoComplete="off"
          placeholder="Naziv kategorije"
          value={newCategory.name || ""}
          maxLength={INPUT_FIELD_MAX_LENGTH}
        />
        <TextInput
          name="shortname"
          onChange={onInputChange}
          autoComplete="off"
          value={newCategory.shortname || ""}
          placeholder="Skraćenica kategorije"
        />
        <TextInput
          name="isSerialNumberRequired"
          type="checkbox"
          onChange={onCheckboxChange}
          label="Serijski broj obavezan za ovu kategoriju?"
          value={newCategory.isSerialNumberRequired || false}
        />

        <StickerPreview
          title={`alpha-${newCategory?.shortname || "?"}-123`}
          subtitle="Samsung TV"
          url="https://inventar.vegait.rs/"
          footer={[newCategory?.name || "?", "Vega IT Alpha"]}
        />
      </div>
      <div className="create-category__controls">
        <Button
          buttonText={"Potvrdi"}
          buttonColor={"blue"}
          event={confirm}
          spinnerColor={"light"}
          isLoading={isBtnLoading}
        />
        <Button buttonText={"Otkaži"} buttonColor={"gray"} event={onCancel} />
      </div>
    </>
  );
};
