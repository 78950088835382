import { useMemo } from "react";
import { Button } from "shared";
import "./confirm-deletion-small-inventory-value.scss";
import { PreviewChangesSmallInventoryValue, useSmallInventoryValueContext } from "domain/SmallInventoryValue";

export const ConfirmDeletionSmallInventoryValue = ({ onConfirm, isBtnLoading }) => {
  const { selectedSmallInventoryValue } = useSmallInventoryValueContext();
  const handleClick = () => {
    onConfirm(selectedSmallInventoryValue.id);
  };

  const smallInventoryValue = useMemo(
    () => ({ id: selectedSmallInventoryValue.id.toString(), isDelete: true }),
    [selectedSmallInventoryValue]
  );

  return (
    <div className="confirm-deletion-small-inventory-value">
      <h2 className="confirm-deletion-small-inventory-value__heading">
        Brisanje vrednosti osnovnih sredstava i inventara
      </h2>
      <div className="confirm-deletion-small-inventory-value__description">
        Da li ste sigurni da želite da obrišete ovu vrednost?
        <br />
        <span className="value">
          Datum: {new Date(selectedSmallInventoryValue.effectiveDate).toLocaleDateString("en-GB")} Vrednost:{" "}
          {selectedSmallInventoryValue.value}
        </span>
      </div>
      <PreviewChangesSmallInventoryValue smallInventoryValue={smallInventoryValue} />
      <Button
        buttonText={"Potvrdi"}
        buttonColor={"red"}
        spinnerColor={"light"}
        event={handleClick}
        isLoading={isBtnLoading}
      />
    </div>
  );
};
