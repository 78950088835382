import React from "react";
import { useNavigate } from "react-router-dom";

import { useAuthentication } from "domain/Authentication";

import { LogoutIcon } from "assets";
import clsx from "clsx";

import "./logout-button.scss";

export const LogoutButton = ({ className }) => {
  const { signOut } = useAuthentication();
  const navigate = useNavigate();

  const handleClick = () => {
    signOut();
    navigate("/login", { state: "/" });
  };

  return (
    <button className={clsx("logout-button", className)} onClick={handleClick}>
      <LogoutIcon className="icon" />
      <span className="logout-button__title">Odjavite se</span>
    </button>
  );
};
