import { ItemDisplayValue } from "../item-display-value/item-display-value";
import { WORK_FROM_HOME } from "shared/constants/constants";
import "./item-display-location.scss";

export const ItemDisplayLocation = ({ item }) => {
  if (!item.location) return <h2 className="item-display__no-item">Nema unetu lokaciju</h2>;

  const workFromHomeOffice = item.location.office === WORK_FROM_HOME;

  return (
    <>
      {workFromHomeOffice ? (
        <ItemDisplayValue label="Naziv kancelarije" value="Radi na daljinu" />
      ) : (
        <>
          <ItemDisplayValue label="Grad" value={item.location.city} />
          <ItemDisplayValue label="Adresa" value={item.location.address} />
          <ItemDisplayValue label="Naziv kancelarije" value={item.location.office} />
          <ItemDisplayValue label="Sprat" value={item.location.floor} />
        </>
      )}
    </>
  );
};
