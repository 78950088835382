import { Dropdown, TextInput } from "shared";
import { censusFilterOptions } from "../../../../shared/constants/constants";
import { InventoryMatchingState } from "../../enums";

export const CensusFilter = ({ isMatchingFilter, setIsMatchingFilter, setShowNotCensused, showNotCensused }) => {
  const onNotCensusedCheckboxChange = (event) => {
    const { checked } = event.target;
    setShowNotCensused(checked);
  };

  const onIsMatchingFilterChange = (selectedOption) => {
    setIsMatchingFilter(selectedOption[0].key);
  };

  return (
    <div className="filters-wrap">
      <TextInput
        disabled={isMatchingFilter !== InventoryMatchingState.ALL_ITEMS}
        name="isInventoryCensused"
        type="checkbox"
        onChange={onNotCensusedCheckboxChange}
        label="Nepopisan inventar"
      />
      <div className="filters__dropdown">
        <Dropdown
          disabled={showNotCensused}
          choices={censusFilterOptions.map(({ name, value }) => ({
            name,
            key: value,
          }))}
          defaultValue={[{ name: "Prikaži sve" }]}
          onChange={onIsMatchingFilterChange}
        />
      </div>
    </div>
  );
};
