import { api } from "../../../shared";

const url = "/api/amortization-types";

async function get(params) {
  return api
    .inventar({
      method: "get",
      url,
      params,
    })
    .then((response) => response.data);
}

async function add(amortizationType) {
  return api
    .inventar({
      method: "post",
      url,
      data: amortizationType,
    })
    .then((response) => response.data);
}

async function remove(amortizationTypeId) {
  return api.inventar({
    method: "delete",
    url: `${url}/${amortizationTypeId}`,
  });
}

export const AmortizationTypeService = {
  get,
  add,
  remove,
};
