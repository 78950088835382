import { api } from "../../../shared";

const url = "/api/history";

async function get(params) {
  return api
    .inventar({
      method: "get",
      url,
      params,
    })
    .then((response) => response.data);
}

async function getById(itemId, params) {
  return api
    .inventar({
      method: "get",
      url: `${url}/${itemId}`,
      params,
    })
    .then((response) => response.data);
}

export const HistoryService = {
  get,
  getById,
};
