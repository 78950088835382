import { useMemo } from "react";

const historyLabels = {
  navigatorId: "Navigator Id",
  itemId: "Evidencioni broj",
  label: "Naziv predmeta",
  invoice: "Broj fakture",
  createdAt: "Vreme akcije",
  actionOwnerName: "Kreator akcije",
  actionType: "Tip akcije",
};

export function useHistoryTableLabels() {
  const columns = useMemo(
    () =>
      Object.keys(historyLabels).map((key) => {
        return {
          Header: historyLabels[key],
          accessor: key,
        };
      }),
    []
  );

  return { columns };
}
