import { useCallback, useState, useEffect } from "react";
import clsx from "clsx";
import { DateInput, TextInput, Dropdown } from "shared";
import { useCompaniesContext } from "domain/Company";
import { useAmortizationTypeContext } from "domain/AmortizationType";
import { INPUT_FIELD_MAX_LENGTH } from "environment";
import { useInvoicesContext } from "domain/Invoice";
import "./simple-inventory-item-template.scss";
import { PRICE_FIELD_MAX_LENGTH } from "environment";
import { useInventoryContext, ItemTypeLabel } from "domain/Inventory";
import { useAmortizationTypeFilters } from "domain/Filters";
import { usePaginatedData } from "shared/hooks/usePaginatedData";
import { useSuppliersContext } from "domain/Supplier/context";
import { Tabs } from "shared";
import { common, InventoryType } from "shared/constants/constants";
import moment from "moment";

export const SimpleInventoryItemTemplate = ({
  values,
  setValues,
  isFieldDirty,
  handleBlur,
  touched,
  isDropdownDirty,
  showInventoryType,
  isPrimaryInventory,
  onInputChange,
  onDropdownChange,
  isItemUpdate,
  setIsFieldChanged,
}) => {
  const { companies } = useCompaniesContext();
  const { invoices, handleInvoicesSearch } = useInvoicesContext();
  const { suppliers } = useSuppliersContext();
  const { amortizationTypes } = useAmortizationTypeContext();
  const { applyPendingAmortizationTypeFilters } = useAmortizationTypeContext();
  const { getWorkspaceLocations } = useInventoryContext();
  const [locations, setLocations] = useState([]);
  const { onAmortizationTypeSearchQueryChange } = useAmortizationTypeFilters();
  const { resetPage, limit, currentPage, nextPage } = usePaginatedData();
  const [searchTerm, setSearchTerm] = useState("");

  const onPriceChange = useCallback(
    (event) => {
      const { value } = event.target;
      if (value.length > PRICE_FIELD_MAX_LENGTH) return;
      setValues((oldState) => ({
        ...oldState,
        amortizationType: {},
        purchasePrice: value,
        totalPrice: value,
      }));
      setIsFieldChanged(true);
    },
    [setValues, setIsFieldChanged]
  );

  const onDateChange = useCallback(
    (purchaseDate) => {
      setValues((oldState) => ({ ...oldState, invoice: { ...oldState.invoice, purchaseDate } }));
      setIsFieldChanged(true);
    },
    [setValues, setIsFieldChanged]
  );

  const onCheckboxChange = useCallback(
    (event) => {
      const { checked } = event.target;
      setValues((oldData) => ({ ...oldData, isActive: checked }));
      setIsFieldChanged(true);
    },
    [setValues, setIsFieldChanged]
  );

  const onInvoiceChange = useCallback(
    (event) => {
      const { value, name } = event.target;
      setValues((oldData) => ({ ...oldData, invoice: { ...oldData.invoice, [name]: value } }));
      setIsFieldChanged(true);
    },
    [setValues, setIsFieldChanged]
  );

  const onSupplierChange = useCallback(
    (event) => {
      const { value, name } = event.target;
      setValues((oldData) => ({ ...oldData, supplier: { [name]: value } }));
      setIsFieldChanged(true);
    },
    [setValues, setIsFieldChanged]
  );

  const getNavigatorIdExample = useCallback(() => {
    const prefix = isPrimaryInventory ? "OS" : "SI";
    return `${prefix}::PC-ALPHA-16`;
  }, [isPrimaryInventory]);

  useEffect(() => {
    applyPendingAmortizationTypeFilters();

    getWorkspaceLocations({ page: currentPage, limit }).then((data) => setLocations(data));
  }, [applyPendingAmortizationTypeFilters, getWorkspaceLocations, currentPage, limit]);

  const handleScroll = () => {
    getWorkspaceLocations({ search: searchTerm, page: currentPage + 1, limit }).then((data) =>
      setLocations((prevData) => [...prevData, ...data])
    );
    nextPage();
  };

  useEffect(() => {
    getWorkspaceLocations({ search: searchTerm, page: currentPage, limit }).then((data) => {
      setLocations(data);
    });
  }, [searchTerm, getWorkspaceLocations, currentPage, limit]);

  const handleLocationSearch = (event) => {
    resetPage();
    setSearchTerm(event);
  };

  const supplierTabs = [
    {
      title: "Odaberite dobavljača",
      component: (
        <div className="choose_supplier">
          <Dropdown
            choices={suppliers}
            onChange={onDropdownChange("supplier")}
            allSelectedLabel="Dobavljač"
            defaultValue={[values.supplier]}
            handleBlur={handleBlur}
            touched={touched}
            dropdownError={isDropdownDirty("supplier")}
            name={"supplier"}
            supplierDropdown={true}
          />
        </div>
      ),
    },
    {
      title: "Novi dobavljač",
      component: (
        <div className="choose_supplier_div">
          <TextInput
            type="text"
            onChange={onSupplierChange}
            name="name"
            label="Novi dobavljač"
            value={values.supplier?.name}
            maxLength={INPUT_FIELD_MAX_LENGTH}
            onBlur={handleBlur}
          />
        </div>
      ),
    },
  ];

  const invoiceTabs = [
    {
      title: "Odaberite fakturu",
      component: (
        <div className="invoice_dropdown-form__wrapper">
          <Dropdown
            className="invoice-dropdown"
            choices={invoices}
            onChange={onDropdownChange("invoice")}
            allSelectedLabel="Fakture"
            defaultValue={[values.invoice]}
            handleBlur={handleBlur}
            touched={touched}
            dropdownError={isDropdownDirty("invoice")}
            name={"invoice"}
            searchId="searchInvoice"
            onSearchChange={handleInvoicesSearch}
          />
        </div>
      ),
    },
    {
      title: "Nova faktura",
      component: (
        <div className="choose_invoice_div">
          <TextInput
            type="text"
            onChange={onInvoiceChange}
            name="number"
            label="Broj fakture"
            value={values.invoice?.number}
            maxLength={INPUT_FIELD_MAX_LENGTH}
            onBlur={handleBlur}
          />
          <label className="date-picker__label">Datum fakture</label>
          <DateInput
            selected={values.invoice?.purchaseDate && new Date(values.invoice?.purchaseDate)}
            onChange={onDateChange}
            value={moment(values.invoice?.purchaseDate).format(common.PREFERRED_FE_DATE_FORMAT)}
          />
          <div className="supplier_div">
            <Tabs items={supplierTabs} />
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <TextInput
        onChange={onInputChange}
        name="label"
        label="Naziv predmeta"
        inputError={isFieldDirty("label")}
        value={values.label}
        maxLength={INPUT_FIELD_MAX_LENGTH}
        onBlur={handleBlur}
        required
      />

      <TextInput
        type="number"
        onChange={onPriceChange}
        name="purchasePrice"
        label="Nabavna cena"
        inputError={isFieldDirty("purchasePrice")}
        maxLength={PRICE_FIELD_MAX_LENGTH}
        value={values.purchasePrice}
        onBlur={handleBlur}
        required
        step=".01"
      />

      {isPrimaryInventory && !isItemUpdate ? (
        <>
          <TextInput type="checkbox" onChange={onCheckboxChange} label="Aktiviraj predmet?" value={values} />
        </>
      ) : null}

      <TextInput
        type="text"
        onChange={onInputChange}
        name="serialNumber"
        label="Serijski broj"
        inputError={isFieldDirty("serialNumber")}
        value={values.serialNumber || ""}
        maxLength={INPUT_FIELD_MAX_LENGTH}
        onBlur={handleBlur}
        required={values.category.isSerialNumberRequired}
      />

      <TextInput
        type="text"
        onChange={onInputChange}
        name="navigatorId"
        label="Navigator ID"
        inputError={isFieldDirty("navigatorId")}
        value={values.navigatorId}
        maxLength={INPUT_FIELD_MAX_LENGTH}
        onBlur={handleBlur}
        hint={`Primer ID-a ako je prazno polje: ${getNavigatorIdExample()}`}
      />
      <div className="invoice_div">
        <Tabs items={invoiceTabs} />
      </div>
      <div className="dropdown-form__wrapper">
        <label>
          Vlasnik<span className="text-input__required">*</span>
        </label>
        <Dropdown
          choices={companies}
          onChange={onDropdownChange("owner")}
          allSelectedLabel="Odaberite vlasnika"
          defaultValue={[values.owner]}
          handleBlur={handleBlur}
          touched={touched}
          dropdownError={isDropdownDirty("owner")}
          name={"owner"}
          disabled={isItemUpdate}
        />
      </div>
      <div className="dropdown-form__wrapper">
        <label>
          Korisnik: <span className="text-input__required">*</span>
        </label>
        <Dropdown
          choices={companies}
          onChange={onDropdownChange("company")}
          allSelectedLabel="Odaberite korisnika"
          defaultValue={[values.company]}
          handleBlur={handleBlur}
          touched={touched}
          dropdownError={isDropdownDirty("company")}
          name={"company"}
        />
      </div>
      {isPrimaryInventory && (
        <div className="dropdown-form__wrapper">
          <label>Tip amortizacije</label>
          <Dropdown
            choices={amortizationTypes.map(({ name, amortizationRate, ...rest }) => ({
              ...rest,
              name: `${name} ${amortizationRate > 0 ? amortizationRate + "%" : ""}`,
            }))}
            onChange={onDropdownChange("amortizationType")}
            allSelectedLabel="Odaberite tip amortizacije"
            defaultValue={[values?.["amortizationType"]]}
            handleBlur={handleBlur}
            touched={touched}
            dropdownError={isDropdownDirty("amortizationType")}
            name={"amortizationType"}
            searchId="searchAmortizationType"
            onSearchChange={onAmortizationTypeSearchQueryChange}
          />
        </div>
      )}
      <div className={clsx("item-attributes__is-small-inventory", { show: showInventoryType })}>
        <ItemTypeLabel
          type={
            values.type === InventoryType.IMMATERIAL_ASSET
              ? values.type
              : isPrimaryInventory
                ? InventoryType.FIXED_ASSET
                : InventoryType.SMALL_INVNENTORY
          }
        />
      </div>

      <TextInput onChange={onInputChange} value={values.assignedTo} label="Dodeljeno kolegi" name="assignedTo" />
      <div className="dropdown-form__wrapper">
        <label>Lokacija</label>
        <Dropdown
          choices={locations}
          onChange={onDropdownChange("location")}
          allSelectedLabel="Odaberite lokaciju"
          defaultValue={[values.location]}
          handleBlur={handleBlur}
          touched={touched}
          dropdownError={isDropdownDirty()}
          name={"location"}
          searchId="searchLocation"
          onScroll={handleScroll}
          paginationConfig
          onSearchChange={handleLocationSearch}
        />
      </div>
      {!isItemUpdate && (
        <TextInput
          type="number"
          onChange={onInputChange}
          value={values.count}
          label="Broj komada"
          name="count"
          inputError={isFieldDirty("count")}
        />
      )}
    </>
  );
};
