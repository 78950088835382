import React from "react";
import { StickerPreview } from "domain/Print";
import { useNavigate } from "react-router-dom";
import { useCensusContext } from "domain/Census";
import { InventoryParts } from "../inventory-parts/inventory-parts";
import { SCAN } from "router";
import clsx from "clsx";

import "./inventory-config.scss";

export const InventoryConfig = ({ item, hasParent = false }) => {
  const { activeCensus } = useCensusContext();
  const navigate = useNavigate();

  const handleClick = (hasParent) => {
    if (hasParent) {
      navigate(`/item/${item.itemId}`);
    }
  };

  const itemCensusStatus =
    item.activeCensus?.isMatching === undefined ? "incomplete" : item.activeCensus.isMatching ? "completed" : "pending";

  return (
    <div className="inventory-config">
      <div className="inventory-config__content">
        <div
          className={`inventory-config__item inventory-config__item--m-bottom${
            !hasParent ? " inventory-config__item--current" : ""
          }`}
          onClick={() => handleClick(hasParent)}
        >
          <StickerPreview
            key={item.itemId}
            title={item.itemId}
            subtitle={item.label}
            description={item.serialNumber}
            url={`${window.location.origin}${SCAN}/${item.itemId}`}
            footer={[item.category?.name, item.company?.name]}
            badge={activeCensus && <div className={clsx("census-badge", { [itemCensusStatus]: true })} />}
          ></StickerPreview>
        </div>
      </div>
      <InventoryParts parts={item.parts} currentActiveCensus={activeCensus} />
    </div>
  );
};
