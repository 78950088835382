import React, { useEffect } from "react";
import clsx from "clsx";
import moment from "moment";
import { ItemsTablePagination } from "../../../Inventory";
import { usePagination, useTable } from "react-table";
import { useNavigate } from "react-router-dom";
import "../census-list/census-list.scss";
import { useFiltersContext } from "../../../Filters";
import { useCensusTableLabels } from "../../hooks/useCensusTableLabels";

export const CensusListTable = ({ data }) => {
  const navigate = useNavigate();
  const { columns } = useCensusTableLabels();
  const { itemsPerView, setItemsPerView } = useFiltersContext();
  const handleRowClick = (censusData) => {
    navigate(`/census/${censusData.id}`, { state: { year: censusData.year, isActive: censusData.isActive } });
  };

  const handlePageSizeChange = (newPageSize) => {
    setItemsPerView(newPageSize);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: itemsPerView },
    },
    usePagination
  );

  useEffect(() => {
    setPageSize(itemsPerView);
  }, [itemsPerView, setPageSize]);

  if (page.length === 0) {
    return <h2 className="no-censuses">Trenutno nema kreiranih popisa.</h2>;
  }

  return (
    <div className="items-table-wrap">
      <div role="table" className="census-item-table" {...getTableProps()}>
        <div className="items-table__header">
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className={clsx("items-table__heading", "table-header")}>
              {headerGroup.headers.map((column) => (
                <div {...column.getHeaderProps()} key={column.id} className="items-table__heading">
                  {column.render("Header")}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="items-table__body" {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <div
                role="row"
                className={clsx("items-table__row census-items-table__grid", {
                  highlighted: row.original.isActive,
                })}
                key={row.original.id}
                onClick={() => handleRowClick(row.original)}
              >
                {row.cells.map((cell) => (
                  <div role="cell" className="items-table__data" {...cell.getCellProps()}>
                    {cell.column.id === "startDate" || cell.column.id === "endDate"
                      ? cell.value
                        ? moment(cell.value).format("DD/MM/YYYY")
                        : "-"
                      : cell.render("Cell")}
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      </div>
      <div className="items-pagination">
        <ItemsTablePagination
          pageCount={pageCount}
          pageIndex={pageIndex}
          gotoPage={gotoPage}
          nextPage={nextPage}
          previousPage={previousPage}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          pageSize={pageSize}
          onPageSizeChange={handlePageSizeChange}
        />
      </div>
    </div>
  );
};
