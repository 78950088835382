import React, { useCallback, useContext, useEffect, useState, useMemo } from "react";

import { SmallInventoryValueService } from "./../services";

const SmallInventoryValueContext = React.createContext();

export function useSmallInventoryValueContext() {
  return useContext(SmallInventoryValueContext);
}

export function SmallInventoryValueProvider({ children }) {
  const [smallInventoryValues, setSmallInventoryValues] = useState([]);

  const [selectedSmallInventoryValue, setSelectedSmallInventoryValue] = useState({});
  const [inventoryPreviews, setInventoryPreviews] = useState([]);
  const latestSmallInventoryValue = useMemo(() => {
    return (
      smallInventoryValues.length && {
        ...smallInventoryValues[smallInventoryValues.length - 1],
      }
    );
  }, [smallInventoryValues]);

  useEffect(() => {
    getSmallInventoryValues();
  }, []);

  const getSmallInventoryValues = () =>
    SmallInventoryValueService.getAll()
      .then(setSmallInventoryValues)
      .catch((error) => {
        throw new Error(error);
      });

  const previewChangesSmallInventoryValues = useCallback(
    (smallInventoryValue) =>
      SmallInventoryValueService.previewChanges(smallInventoryValue)
        .then(setInventoryPreviews)
        .catch((error) => {
          throw new Error(error);
        }),
    []
  );

  const addSmallInventoryValue = (newSmallInventoryValue) =>
    SmallInventoryValueService.add(newSmallInventoryValue)
      .then((smallInventoryValue) => {
        setSmallInventoryValues([...smallInventoryValues, smallInventoryValue]);
      })
      .catch((error) => {
        throw new Error(error);
      });

  const updateSmallInventoryValue = (newSmallInventoryValue) =>
    SmallInventoryValueService.update(newSmallInventoryValue)
      .then((smallInventoryValue) => {
        const index = smallInventoryValues.findIndex((value) => value.id === smallInventoryValue.id);
        const newSmallInventoryValues = [
          ...smallInventoryValues.slice(0, index),
          smallInventoryValue,
          ...smallInventoryValues.slice(index + 1, smallInventoryValues.length),
        ];
        setSmallInventoryValues(newSmallInventoryValues);
      })
      .catch((error) => {
        throw new Error(error);
      });

  const archiveSmallInventoryValue = (id) =>
    SmallInventoryValueService.archive(id)
      .then(() => {
        const index = smallInventoryValues.findIndex((value) => value.id === id);
        const newSmallInventoryValues = [
          ...smallInventoryValues.slice(0, index),
          ...smallInventoryValues.slice(index + 1, smallInventoryValues.length),
        ];
        setSmallInventoryValues(newSmallInventoryValues);
      })
      .catch((error) => {
        throw new Error(error);
      });

  const findClosest = (targetDate, isFuture) => {
    let closestSmallInventoryValue = null;
    let smallestDifference = Number.MAX_SAFE_INTEGER;
    smallInventoryValues.forEach((smallInventoryValue) => {
      const currentDate = new Date(smallInventoryValue.effectiveDate);
      const difference = isFuture
        ? currentDate.getTime() - targetDate.getTime()
        : targetDate.getTime() - currentDate.getTime();
      if (difference > 0 && difference < smallestDifference) {
        smallestDifference = difference;
        closestSmallInventoryValue = smallInventoryValue;
      }
    });

    return closestSmallInventoryValue;
  };

  const findClosestInPast = (targetDate) => {
    return findClosest(targetDate, false);
  };

  return (
    <SmallInventoryValueContext.Provider
      value={{
        inventoryPreviews,
        smallInventoryValues,
        latestSmallInventoryValue,
        addSmallInventoryValue,
        updateSmallInventoryValue,
        archiveSmallInventoryValue,
        selectedSmallInventoryValue,
        setSelectedSmallInventoryValue,
        previewChangesSmallInventoryValues,
        findClosestInPast,
      }}
    >
      {children}
    </SmallInventoryValueContext.Provider>
  );
}
