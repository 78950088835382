import React, { useContext, useEffect, useState } from "react";
import { SupplierService } from "../services/supplier.service";
import { useAuthentication } from "domain/Authentication";

const SupplierContext = React.createContext({});

export function useSuppliersContext() {
  return useContext(SupplierContext);
}

export function SuppliersProvider({ children }) {
  const [suppliers, setSuppliers] = useState([]);
  const { role } = useAuthentication();

  useEffect(() => {
    if (role === "Visitor") return;
    SupplierService.get().then(setSuppliers);
  }, [role]);

  return (
    <SupplierContext.Provider
      value={{
        suppliers,
      }}
    >
      {children}
    </SupplierContext.Provider>
  );
}
