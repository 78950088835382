import { useMemo } from "react";

const censusDetailsLabels = {
  label: "Naziv uređaja",
  navigatorId: "Navigator id",
  companyName: "Vlasništvo",
  isMatching: "Podaci se poklapaju",
  description: "Opis",
  edit: "",
};

export function useCensusDetailsTableLabels() {
  const columns = useMemo(
    () =>
      Object.keys(censusDetailsLabels).map((key) => {
        return {
          Header: censusDetailsLabels[key],
          accessor: key,
        };
      }),
    []
  );

  return { columns };
}
