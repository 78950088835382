import React from "react";

import "./settings-content-heading.scss";
export const SettingsContentHeading = ({ heading, description, warning }) => {
  return (
    <>
      <h2 className="heading">{heading}</h2>
      <div className="description">{description}</div>
      {warning && <div className="warning">{warning}</div>}
    </>
  );
};
