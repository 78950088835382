import React from "react";

import { Button } from "shared";

import { SettingsContentHeading } from "../settings-content-heading/settings-content-heading";
import { SettingsContentList } from "../settings-content-list/settings-content-list";
import "./settings-content.scss";
export const SettingsContent = ({
  heading,
  description,
  warning,
  items,
  itemTitle,
  buttonText,
  buttonColor,
  buttonIcon,
  event,
  onDelete,
  hasDelete,
  deleteMessage,
  isBtnLoading,
}) => {
  return (
    <div className="settings__content">
      <SettingsContentHeading heading={heading} description={description} warning={warning}></SettingsContentHeading>
      <SettingsContentList
        items={items}
        itemTitle={itemTitle}
        onDelete={onDelete}
        hasDelete={hasDelete}
        firstItem="Racunar/Monitor"
        deleteMessage={deleteMessage}
        isBtnLoading={isBtnLoading}
      />
      <Button buttonText={buttonText} buttonColor={buttonColor} buttonIcon={buttonIcon} event={event} />
    </div>
  );
};
