import { api } from "../../../shared";

const url = "/api/small-inventory-values";

async function getAll() {
  return api
    .inventar({
      method: "get",
      url,
    })
    .then((response) => response.data);
}

async function getLatest() {
  return api
    .inventar({
      method: "get",
      url: `${url}/latest`,
    })
    .then((response) => response.data);
}

async function previewChanges(smallInventoryValue) {
  return api
    .inventar({
      method: "get",
      url: `${url}/preview-changes`,
      params: smallInventoryValue,
    })
    .then((response) => response.data);
}

async function add(smallInventoryValue) {
  return api
    .inventar({
      method: "post",
      url,
      data: smallInventoryValue,
    })
    .then((response) => response.data);
}

async function update(smallInventoryValue) {
  return api
    .inventar({
      method: "put",
      url,
      data: smallInventoryValue,
    })
    .then((response) => response.data);
}

async function archive(id) {
  return api
    .inventar({
      method: "delete",
      url: `${url}/${id}`,
    })
    .then((response) => response.data);
}

export const SmallInventoryValueService = {
  getAll,
  getLatest,
  previewChanges,
  add,
  update,
  archive,
};
