import { useState } from "react";
import { useSmallInventoryValueContext } from "domain/SmallInventoryValue";
import { useEffect } from "react";
import { SmallInventoryForm } from "domain/Inventory";

export const UpdateSmallInventoryForm = ({ onSubmit, isBtnLoading }) => {
  const { selectedSmallInventoryValue, smallInventoryValues } = useSmallInventoryValueContext();

  const [minDate, setMinDate] = useState(new Date(0));
  const [maxDate, setMaxDate] = useState(new Date());

  const initPrice = smallInventoryValues.find((item) => item.id === selectedSmallInventoryValue.id);
  const inventoryIndex = smallInventoryValues.findIndex(
    (smallInventoryValue) => smallInventoryValue.id === selectedSmallInventoryValue.id
  );

  useEffect(() => {
    if (smallInventoryValues.length <= 1) {
      setMinDate(new Date(0));
      setMaxDate(new Date());
    } else if (inventoryIndex === 0 && smallInventoryValues.length > 1) {
      setMinDate(new Date(0));
      setMaxDate(new Date(smallInventoryValues[1].effectiveDate));
    } else if (inventoryIndex === smallInventoryValues.length - 1 && smallInventoryValues.length > 1) {
      setMinDate(new Date(smallInventoryValues[inventoryIndex - 1].effectiveDate));
      setMaxDate(new Date());
    } else {
      setMinDate(new Date(smallInventoryValues[inventoryIndex - 1].effectiveDate));
      setMaxDate(new Date(smallInventoryValues[inventoryIndex + 1].effectiveDate));
    }
  }, [inventoryIndex, smallInventoryValues]);

  const [inventoryInfo, setInventoryInfo] = useState({
    ...selectedSmallInventoryValue,
    effectiveDate: new Date(selectedSmallInventoryValue.effectiveDate),
  });

  return (
    <SmallInventoryForm
      title="Promena vrednosti osnovnih sredstava i inventara"
      onSubmit={onSubmit}
      isBtnLoading={isBtnLoading}
      inventoryInfo={inventoryInfo}
      setInventoryInfo={setInventoryInfo}
      initPrice={initPrice.value}
      minDate={minDate}
      maxDate={maxDate}
    />
  );
};
