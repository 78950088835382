import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useCensusContext } from "../../context";
import { GoPlus } from "react-icons/go";
import { Button, ModalConfirmation, useModalContext } from "../../../../shared";
import { CreateCensusForm } from "../../../Inventory";
import { CensusListTable } from "../census-list-table/census-list-table";
import { CensusProgress } from "../census-progress/census-progress";
import "./census-list.scss";

export const CensusList = () => {
  const { censuses, addCensus, getCensuses, progress } = useCensusContext();
  const { createModal } = useModalContext();
  const [showDetailedProgress, setShowDetailedProgress] = useState(false);
  const [isCreateBtnLoading, setIsCreateBtnLoading] = useState(false);
  const [createInventoryErrorMessage, setCreateInventoryErrorMessage] = useState("");
  const { element: CreateNewCensusModal, open, close } = useMemo(() => createModal(), [createModal]);
  const {
    element: CloseNewItemModalConfirmation,
    open: openConfirmationModal,
    close: closeConfirmationModal,
  } = useMemo(() => createModal(), [createModal]);

  useEffect(() => {
    getCensuses();
  }, [getCensuses]);
  const onCreateNewCensus = useCallback(
    (newCensus, count) => {
      setIsCreateBtnLoading(true);
      addCensus(newCensus, count)
        .then(() => close())
        .catch((error) => setCreateInventoryErrorMessage(error.response.data))
        .finally(() => setIsCreateBtnLoading(false));
    },
    [addCensus, close]
  );

  const onCloseConfirmationModal = () => {
    closeConfirmationModal();
  };

  const onSubmitConfirmationModal = () => {
    closeConfirmationModal();
    close();
  };

  const toggleDetailedProgress = () => {
    setShowDetailedProgress(!showDetailedProgress);
  };

  return (
    <div className="scroll">
      <CensusProgress
        progress={progress}
        showDetailedProgress={showDetailedProgress}
        toggleDetailedProgress={toggleDetailedProgress}
      />

      <Button
        buttonText={"Kreiraj novi popis"}
        buttonColor={"blue"}
        event={(e) => {
          setIsCreateBtnLoading(false);
          setCreateInventoryErrorMessage("");
          open();
        }}
        buttonIcon={<GoPlus className="btn__plus-icon" />}
      />

      <CensusListTable data={censuses} />

      <CreateNewCensusModal>
        <CreateCensusForm
          onSubmit={onCreateNewCensus}
          onCancel={openConfirmationModal}
          isBtnLoading={isCreateBtnLoading}
          errorMessage={createInventoryErrorMessage}
        />
      </CreateNewCensusModal>

      <CloseNewItemModalConfirmation>
        <ModalConfirmation
          onSubmit={onSubmitConfirmationModal}
          onCancel={onCloseConfirmationModal}
          text={<p>Da li ste sigurni da želite da zatvorite prozor?</p>}
        />
      </CloseNewItemModalConfirmation>
    </div>
  );
};
