import React from "react";
import { StickerPreview } from "./../sticker-preview/sticker-preview";
import { ParentGroupSticker } from "domain/Print";
import { PersonGroupSticker } from "./../person-group-sticker/person-group-sticker";

export const LocationGroupSticker = ({ group, isOnlyOne, locationSticker = true }) => {
  const { location, items } = group;

  return (
    <div>
      {((!isOnlyOne && locationSticker) || locationSticker) && <StickerPreview title={location} />}
      {items?.map((item) => {
        const isWorkstationAvailable = Object.keys(item.workstation).length > 0;
        const areItemsWithoutWorkstationAvailable = item.itemsWithoutWorkstation.length > 0;

        return (
          <div key={item.assignedTo}>
            {isWorkstationAvailable && <ParentGroupSticker key={item.workstation.itemId} item={item.workstation} />}
            {areItemsWithoutWorkstationAvailable && (
              <PersonGroupSticker
                key={item.itemsWithoutWorkstation[0].assignedTo}
                items={item.itemsWithoutWorkstation}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};
