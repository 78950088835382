import React from "react";

import "./configuration-changes-section.scss";

import { FaArrowRight } from "react-icons/fa";

import { ItemTypeLabel } from "domain/Inventory";

import { partChangeLabels } from "shared/constants/constants";

export const ConfigurationChangesSection = ({ data }) => {
  return (
    <div>
      {data.map((change) => {
        return (
          <div className="configuration-changes__state_section" key={change.description}>
            <p>{change.description}</p>
            <div className="configuration-changes__changed_state">
              {change.description === partChangeLabels.get("TYPE") ? (
                <ItemTypeLabel type={change.stateBefore} />
              ) : (
                change.stateBefore
              )}
              <FaArrowRight className="btn__arrow-icon" />
              {change.description === partChangeLabels.get("TYPE") ? (
                <ItemTypeLabel type={change.stateAfter} />
              ) : (
                change.stateAfter
              )}
            </div>
            <hr className="line" />
          </div>
        );
      })}
    </div>
  );
};
