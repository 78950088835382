import { api } from "../../../shared";

const url = "/api/auth";

async function logIn(token) {
  return api
    .inventar({
      method: "post",
      url: `${url}/google`,
      data: { token: token },
    })
    .then((response) => response.data);
}

async function logOut() {
  return api
    .inventar({
      method: "post",
      url: `${url}/google/logout`,
    })
    .then((response) => response.data);
}

export const AuthenticationService = {
  logIn,
  logOut,
};
