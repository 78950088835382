import { StickerPreview } from "../sticker-preview/sticker-preview";
import { ItemSticker } from "../item-sticker/item-sticker";

export const PersonGroupSticker = ({ items }) => {
  const { company, location, assignedTo = "Neraspoređeno" } = items[0];
  const { office, address } = location;

  return (
    <>
      <StickerPreview
        title={assignedTo}
        subtitle={company.name}
        description={office || address}
        footer={[`${items.length} item${items.length > 1 ? "s" : ""}`]}
      />
      {items.map((item) => {
        return <ItemSticker key={item.itemId} item={item} />;
      })}
    </>
  );
};
