import { useMemo } from "react";

const censusLabels = {
  year: "Godina",
  description: "Opis",
  startDate: "Datum početka",
  endDate: "Datum kraja",
};

export function useCensusTableLabels() {
  const columns = useMemo(
    () =>
      Object.keys(censusLabels).map((key) => {
        return {
          Header: censusLabels[key],
          accessor: key,
        };
      }),
    []
  );

  return { columns };
}
