import React, { useState, useContext, useEffect, useCallback } from "react";

import { useCategoriesContext } from "domain/Category";
import { useCompaniesContext } from "domain/Company";
import { InventoryService } from "../services";
import { InventoryPartService } from "domain/InventoryPart";
import { useSmallInventoryValueContext } from "domain/SmallInventoryValue";
import { useFiltersContext } from "domain/Filters";
import { useSortingContext } from "domain/Sorting";

import { InventoryType, partChangeLabels } from "shared/constants/constants";
import { HistoryService } from "domain/History";
import { useAmortizationTypeContext } from "domain/AmortizationType";
import { LocationService } from "domain/Location";
import { useSuppliersContext } from "domain/Supplier/context";
import { InventoryCensusService } from "../../Census/services/inventory-census.service";

const InventoryContext = React.createContext();

export function useInventoryContext() {
  return useContext(InventoryContext);
}

export function InventoryProvider({ children }) {
  const { categories } = useCategoriesContext();
  const { companies } = useCompaniesContext();
  const { suppliers } = useSuppliersContext();
  const { amortizationTypes } = useAmortizationTypeContext();
  const { smallInventoryValues } = useSmallInventoryValueContext();
  const { applyFilters } = useFiltersContext();
  const [items, setItems] = useState();
  const [parts, setParts] = useState([]);
  const [isValidToAddNewItem, setIsValidToAddNewItem] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const { applySorting } = useSortingContext();
  const [changes, setChanges] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getItem = useCallback(
    async (itemId) => {
      return InventoryService.get({ itemId }).then((data) => {
        const { companyId, categoryId, invoiceId, amortizationTypeId, parts, supplierId, ownerId, ...other } = data;
        const company = companies.find((company) => company.id === companyId);
        const owner = companies.find((owner) => owner.id === ownerId);
        const category = categories.find((category) => category.id === categoryId);
        const amortizationType = amortizationTypes.find(
          (amortizationType) => amortizationType.id === amortizationTypeId
        );

        return {
          ...other,
          company,
          owner,
          category,
          amortizationType,
          parts: parts
            ? parts.map((part) => {
                return {
                  ...part,
                  category: categories.find((category) => category.id === part.categoryId),
                  company: companies.find((company) => company.id === part.companyId),
                  owner: companies.find((owner) => owner.id === part.ownerId),
                  supplier: suppliers?.find((supplier) => supplier.id === part.invoice?.supplierId),
                };
              })
            : [],
        };
      });
    },
    [amortizationTypes, categories, companies, suppliers]
  );

  const getAllItems = useCallback(
    async (items) => {
      try {
        return await Promise.all(items.map((item) => getItem(item.itemId)));
      } catch (error) {
        alert(error);
      }
    },
    [getItem]
  );

  const updateItem = async (item) => {
    return InventoryService.update(item).then((inventory) => {
      return inventory.updatedInventory;
    });
  };

  const changeItemOwnership = async (inventoryId, newOwnerCompany, newPurchasePrice) => {
    return InventoryService.changeItemOwnership(inventoryId, newOwnerCompany, newPurchasePrice).then((inventory) => {
      return inventory;
    });
  };

  const getIsItemSmallInventory = useCallback(async (item) => {
    return InventoryService.getIsSmallInventory(item).then(({ isSmallInventory }) => isSmallInventory);
  }, []);

  const addItemToCensus = async (item) => {
    return InventoryCensusService.addItem(item);
  };

  const addItem = async (item, count) => {
    const { isActive } = item;

    item.activationDate = isActive ? new Date() : null;

    try {
      const createdItems = await InventoryService.add(item, count);
      const newItems = createdItems.map((createdItem) => {
        const { companyId, categoryId, ownerId } = createdItem;
        const company = companies.find((company) => company.id === companyId);
        const owner = companies.find((owner) => owner.id === ownerId);
        const category = categories.find((category) => category.id === categoryId);

        return { ...createdItem, company, owner, category };
      });

      setItems((oldItems) => [...oldItems, ...newItems]);

      return newItems;
    } catch (error) {
      console.error("Error adding item:", error);
      throw error;
    }
  };

  const addInventoryParts = async (inventoryId, items) =>
    InventoryPartService.addParts({ inventoryId, partIds: items.map((item) => item.id) }).then((newParts) => {
      const inventoryParts = newParts.map((part) => items.find((inventory) => inventory.id === part.partId));
      setItems((prevState) =>
        prevState.map((item) => {
          if (item.id !== newParts[0].inventoryId) {
            return item;
          }
          const { parts, ...other } = item;
          return {
            ...other,
            parts: [...parts, ...inventoryParts],
          };
        })
      );
    });

  const validateAllItems = async (inventoryId, items) => {
    const partIds = items.map((part) => part.id.toString());
    InventoryPartService.addParts({ inventoryId, partIds, validateOnly: true }).then((data) => {
      const { parentChanges, partsChanges } = data;
      const changes = [];

      if (parentChanges.stateAfter.totalPrice !== parentChanges.stateBefore.totalPrice) {
        changes.push({
          description: partChangeLabels.get("TOTAL_PRICE"),
          stateBefore: parentChanges.stateBefore.totalPrice,
          stateAfter: parentChanges.stateAfter.totalPrice,
        });
      }

      if (parentChanges.stateAfter.type !== parentChanges.stateBefore.type) {
        changes.push({
          description: partChangeLabels.get("TYPE"),
          stateBefore: InventoryType.SMALL_INVNENTORY,
          stateAfter: InventoryType.FIXED_ASSET,
        });
      }

      if (partsChanges.stateAfter[0].type !== partsChanges.stateBefore[0].type) {
        changes.push({
          description: partChangeLabels.get("TYPE"),
          stateBefore: InventoryType.SMALL_INVNENTORY,
          stateAfter: InventoryType.FIXED_ASSET,
        });
      }

      if (partsChanges.stateAfter[0].assignedTo !== partsChanges.stateBefore[0].assignedTo) {
        changes.push({
          description: partChangeLabels.get("ASSIGNED_TO"),
          stateBefore: partsChanges.stateBefore[0].assignedTo,
          stateAfter: partsChanges.stateAfter[0].assignedTo
            ? partsChanges.stateAfter[0].assignedTo
            : "slobodan inventar",
        });
      }
      setChanges(changes);
    });
  };

  const updateSelection = (selectionArray) => setSelectedItems(selectionArray);

  const serialNumberAlreadyExist = (serialNumber) => {
    const params = { serialNumber: serialNumber };
    InventoryService.get(params).then((response) => {
      if (response.length === 0) {
        return setIsValidToAddNewItem(true);
      }
      return setIsValidToAddNewItem(false);
    });
  };

  const getParts = useCallback(
    async (inventoryId, searchTerm) => {
      const params = { isPart: true, exclude: inventoryId, searchTerm: searchTerm };
      return InventoryService.get(params).then((response) => {
        const parts = response.map((part) => {
          const company = companies.find((company) => company.id === part.companyId);
          const owner = companies.find((owner) => owner.id === part.ownerId);
          const category = categories.find((category) => category.id === part.categoryId);
          return {
            itemId: part.itemId,
            name: part.label,
            serialNumber: part.serialNumber,
            navigatorId: part.navigatorId,
            value: part.id,
            company,
            owner,
            category,
          };
        });
        setParts(parts);
      });
    },
    [categories, companies]
  );

  const getParentById = useCallback(
    (id) => {
      return InventoryPartService.getParentById(id).then((data) => {
        if (!data) return;

        const { companyId, categoryId, invoiceId, ownerId, ...other } = data;
        const company = companies.find((company) => company.id === companyId);
        const owner = companies.find((owner) => owner.id === ownerId);
        const category = categories.find((category) => category.id === categoryId);

        return {
          ...other,
          company,
          owner,
          category,
        };
      });
    },
    [categories, companies]
  );

  const getHistoryById = (id, params) => {
    return HistoryService.getById(id, params).then((data) => data);
  };

  useEffect(() => {
    if (!categories.length || !companies.length || !smallInventoryValues.length) return;
    const params = { ...applyFilters, ...applySorting };

    setIsLoading(true);
    InventoryService.get(params)
      .then((data) => {
        setItems(
          data.map((item) => {
            const { companyId, categoryId, ownerId, supplierId, ...other } = item;
            const company = companies.find((company) => company.id === companyId);
            const owner = companies.find((owner) => owner.id === ownerId);
            const category = categories.find((category) => category.id === categoryId);
            const supplier = suppliers.find((supplier) => supplier.id === item.invoice?.supplierId);

            return {
              ...other,
              company,
              category,
              owner,
              supplier,
              parts: item.parts.map((part) => {
                return {
                  ...part,
                  category: categories.find((category) => category.id === part.categoryId),
                  company: companies.find((company) => company.id === part.companyId),
                  owner: companies.find((owner) => owner.id === part.ownerId),
                  supplier: suppliers?.find((supplier) => supplier.id === item.invoice?.supplierId),
                };
              }),
            };
          })
        );
      })
      .finally(() => setIsLoading(false));
  }, [applyFilters, companies, suppliers, categories, smallInventoryValues, applySorting]);

  const deleteInventory = async (inventoryId, deleteReason) => {
    return await InventoryService.deleteInventory(inventoryId, deleteReason).then(() => {
      setItems((prevState) => prevState.filter((item) => item.id !== inventoryId));
    });
  };

  const deleteInventoryPart = async (inventoryId, partId) => {
    return InventoryPartService.deletePart(inventoryId, partId).then(() => {
      setItems((prevState) =>
        prevState.map((item) => {
          if (item.id !== inventoryId) {
            return item;
          }
          const { parts, ...other } = item;
          return {
            ...other,
            parts: parts.filter((part) => part.id !== partId),
          };
        })
      );
    });
  };

  const getWorkspaceLocations = async (params) => {
    return LocationService.getWorkspaceLocations(params);
  };

  return (
    <InventoryContext.Provider
      value={{
        items,
        parts,
        isValidToAddNewItem,
        serialNumberAlreadyExist,
        getItem,
        getAllItems,
        validateAllItems,
        addInventoryParts,
        getParts,
        addItem,
        selectedItems,
        updateSelection,
        changes,
        getParentById,
        getHistoryById,
        updateItem,
        deleteInventory,
        deleteInventoryPart,
        getWorkspaceLocations,
        addItemToCensus,
        getIsItemSmallInventory,
        changeItemOwnership,
        isLoading,
      }}
    >
      {children}
    </InventoryContext.Provider>
  );
}
