import { api } from "../../../shared";

const url = "/api/workspace";

async function getWorkspaceLocations(params) {
  return api
    .inventar({
      method: "get",
      url: `${url}/locations`,
      params,
    })
    .then((response) => response.data);
}

export const LocationService = {
  getWorkspaceLocations,
};
