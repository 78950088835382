import React, { useState, useContext, useCallback, useEffect } from "react";
import { useLocalStorage } from "../../App/hooks/useLocalStorage";
import { HistoryService } from "../services";
import { sortingOptions, sortingDirectionMap } from "shared/constants/constants";

const HistoryContext = React.createContext();
const DEFAULT_ITEMS_PER_VIEW = 100;
const ITEMS_PER_VIEW = "itemsPerView";

export function useHistoryContext() {
  return useContext(HistoryContext);
}

const initialSortingValue = {
  name: sortingOptions[0].name,
  key: sortingOptions[0].value,
  direction: sortingDirectionMap.get("DESCEND"),
};

export function HistoryProvider({ children }) {
  const [histories, setHistories] = useState([]);
  const [exportHistoryItems, setExportHistoryItems] = useState([]);
  const [applyHistoryFilters, setApplyHistoryFilters] = useState({});
  const [pendingHistoryFilters, setPendingHistoryFilters] = useState({});
  const [applyHistorySorting, setApplyHistorySorting] = useState({});
  const [pendingHistorySort, setPendingHistorySort] = useState(initialSortingValue);

  const saveHistoryFilter = useCallback(
    (filter) => {
      setPendingHistoryFilters((existing) => {
        if (filter.values.length === 0) return existing;

        return {
          ...existing,
          [filter.name]: filter.values.join(","),
        };
      });
    },
    [setPendingHistoryFilters]
  );

  const removeHistoryFilter = useCallback(
    (filterName) => {
      setPendingHistoryFilters((existing) => {
        if (Object.keys(existing).length === 0 && typeof existing === "object" && existing != null) return {};

        const cleanedFilters = { ...existing };
        delete cleanedFilters[filterName];
        return cleanedFilters;
      });
    },
    [setPendingHistoryFilters]
  );

  const applyPendingHistoryFilters = useCallback(() => {
    setApplyHistoryFilters(pendingHistoryFilters);
  }, [pendingHistoryFilters]);

  const clearFilters = () => {
    setApplyHistoryFilters({});
    setPendingHistoryFilters({});
    setExportHistoryItems([]);
  };

  const applyHistorySortingHandler = useCallback(() => {
    setApplyHistorySorting(pendingHistorySort);
  }, [pendingHistorySort]);

  const clearHistorySorting = () => {
    setApplyHistorySorting({});
    setPendingHistorySort(initialSortingValue);
  };

  useEffect(() => {
    const params = { ...applyHistoryFilters, ...applyHistorySorting };
    HistoryService.get(params).then((response) => {
      setHistories(response);
    });
  }, [applyHistoryFilters, applyHistorySorting]);

  const [itemsPerView, setItemsPerView] = useLocalStorage(ITEMS_PER_VIEW, DEFAULT_ITEMS_PER_VIEW);

  return (
    <HistoryContext.Provider
      value={{
        histories,
        applyHistoryFilters,
        pendingHistoryFilters,
        removeHistoryFilter,
        saveHistoryFilter,
        itemsPerView,
        setItemsPerView,
        applyPendingHistoryFilters,
        exportHistoryItems,
        clearFilters,
        applyHistorySortingHandler,
        clearHistorySorting,
        pendingHistorySort,
        setPendingHistorySort,
      }}
    >
      {children}
    </HistoryContext.Provider>
  );
}
