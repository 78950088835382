import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { INDEX, SETTINGS, LOGIN, SCAN, ITEM, EXPORT, HISTORY, CENSUS } from "router";
import { HomePage, ErrorPage, App } from "domain/App";
import { LoginPage } from "domain/Authentication";
import { InventoryPage, ScanPage } from "domain/Inventory";
import { SettingsPage } from "domain/Settings";
import { ExportPage } from "domain/Export";
import { HistoryPage } from "domain/History";
import { useAuthentication } from "../domain/Authentication/context/index";
import { CensusList } from "../domain/Census";
import { CensusDetails } from "../domain/Census";

export const GlobalRouter = () => {
  const { hasAccess } = useAuthentication();
  return (
    <BrowserRouter>
      <Routes>
        <Route path={LOGIN} element={<LoginPage />} />
        <Route path={INDEX} element={<App />}>
          <Route path={SCAN}>
            <Route path=":id" element={<ScanPage />} />
          </Route>
          {hasAccess && (
            <>
              <Route index element={<HomePage />} />
              <Route path={ITEM}>
                <Route path=":id" element={<InventoryPage />} />
              </Route>
              <Route path={SETTINGS} element={<SettingsPage />} />
              <Route path={EXPORT} element={<ExportPage />} />
              <Route path={HISTORY} element={<HistoryPage />} />
              <Route path={CENSUS} element={<CensusList />}></Route>
              <Route path="census/:id" element={<CensusDetails />} />
            </>
          )}
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
};
