import { useAmortizationTypeContext } from "domain/AmortizationType";

export function useAmortizationTypeFilters() {
  const { saveAmortizationTypeFilter, removeAmortizationTypeFilter } = useAmortizationTypeContext();

  const handleAmortizationTypeFilterBySearch = (term, filterName = "searchTerm") => {
    if (!term) {
      removeAmortizationTypeFilter(filterName);
    } else {
      saveAmortizationTypeFilter({
        name: filterName,
        values: [term],
      });
    }
  };

  return {
    onAmortizationTypeSearchQueryChange: (term) => handleAmortizationTypeFilterBySearch(term),
  };
}
