import { useState } from "react";
import { useSmallInventoryValueContext } from "domain/SmallInventoryValue";
import { SmallInventoryForm } from "domain/Inventory";

export const CreateSmallInventoryForm = ({ onSubmit, isBtnLoading }) => {
  const { latestSmallInventoryValue } = useSmallInventoryValueContext();

  const [inventoryInfo, setInventoryInfo] = useState(() => {
    if (latestSmallInventoryValue) {
      return { ...latestSmallInventoryValue, effectiveDate: new Date() };
    }
    return { value: 0, effectiveDate: new Date() };
  });

  return (
    <SmallInventoryForm
      title="Dodavanje vrednosti osnovnih sredstava i inventara"
      onSubmit={onSubmit}
      isBtnLoading={isBtnLoading}
      inventoryInfo={inventoryInfo}
      setInventoryInfo={setInventoryInfo}
      createNewValue
      minDate={latestSmallInventoryValue ? new Date(latestSmallInventoryValue.effectiveDate) : new Date(0)}
      maxDate={new Date()}
    />
  );
};
