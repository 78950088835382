import React, { useEffect, useState } from "react";

import { Button, Dropdown, DateInput } from "shared";
import { BsFilter } from "react-icons/bs";
import { IoClose } from "react-icons/io5";

import { useEmployeesContext } from "domain/Employee";
import { SearchBar } from "shared";
import { SortingGroup } from "domain/Sorting";
import { useHistoryContext, useItemHistoryFilters } from "domain/History";
import { sortingHistoryOptions, actionTypeFilters } from "shared/constants/constants";
import { common } from "shared/constants/constants";

import clsx from "clsx";
import moment from "moment";

import "./history-filters-group.scss";

export const HistoryFiltersGroup = () => {
  const [showFilters, setShowFilters] = useState(false);
  const [clearChoices, setClearChoices] = useState(false);
  const [historyStartDate, setHistoryStartDate] = useState(null);
  const [historyEndDate, setHistoryEndDate] = useState(null);
  const { employees, handleEmployeeSearch } = useEmployeesContext();
  const { applyPendingHistoryFilters, clearFilters, applyHistorySortingHandler, clearHistorySorting } =
    useHistoryContext();
  const { onSearchQueryChange, onHistoryActionsFilterChange, onActionCreatorFilterChange, onHistoryDateFilterChange } =
    useItemHistoryFilters();

  const toggleFiltersHandler = () => {
    setShowFilters(!showFilters);
  };

  const closeFiltersHandler = () => {
    setShowFilters(false);
  };

  const clearFiltersHandler = () => {
    clearFilters();
    clearHistorySorting();
    setClearChoices(true);
  };

  const updateClearHandler = () => {
    setClearChoices(false);
  };

  const applyFiltersHandler = () => {
    applyPendingHistoryFilters();
    applyHistorySortingHandler();
    closeFiltersHandler();
  };

  const onHistoryStartDateChange = (date) => {
    setHistoryStartDate(date);
    const filter = { name: "historyStartDate", value: moment(date).format(common.PREFERRED_BE_DATE_FORMAT) };
    onHistoryDateFilterChange(filter);
  };

  const onHistoryEndDateChange = (date) => {
    setHistoryEndDate(date);
    const filter = { name: "historyEndDate", value: moment(date).format(common.PREFERRED_BE_DATE_FORMAT) };
    onHistoryDateFilterChange(filter);
  };

  useEffect(() => {
    if (showFilters) return;
    applyPendingHistoryFilters();
  }, [showFilters, applyPendingHistoryFilters]);

  return (
    <div className="filters">
      <div className="filters__search">
        <SearchBar
          onChange={onSearchQueryChange}
          placeholder="Pretraži"
          autoComplete="off"
          spellCheck="false"
          id="searchQuery"
        ></SearchBar>
        <button
          className={clsx("filters__btn", { "filters__btn--active": showFilters })}
          onClick={toggleFiltersHandler}
        >
          <BsFilter />
        </button>
      </div>
      <div className={clsx("filters__container", { "filters__container--active": showFilters })}>
        <div className="filters__wrap">
          <div className="filters__close">
            <Button
              buttonIcon={<IoClose className="filters__close-icon" />}
              buttonColor={"red"}
              event={closeFiltersHandler}
            />
          </div>
          <div className="filters__options">
            <div className="filters__field">
              <p className="filters__name">Tip akcije</p>
              <Dropdown
                choices={actionTypeFilters}
                onChange={(historyAction) => {
                  updateClearHandler();
                  return onHistoryActionsFilterChange(historyAction);
                }}
                isMultipleChoice
                allSelectedLabel="Sve"
                clearChoices={clearChoices}
              />
            </div>
            <div className="filters__field">
              <p className="filters__name">Kreator akcije</p>
              <Dropdown
                choices={employees}
                onChange={(employees) => {
                  updateClearHandler();
                  return onActionCreatorFilterChange(employees);
                }}
                isMultipleChoice
                allSelectedLabel="Sve kolege"
                searchId="searchEmployees"
                onSearchChange={handleEmployeeSearch}
                clearChoices={clearChoices}
              />
            </div>
            <div className="filters__sort-wrap">
              <SortingGroup
                clearChoices={clearChoices}
                onSelection={updateClearHandler}
                sortingOptions={sortingHistoryOptions}
              />
            </div>
          </div>
          <hr />
          <h2>Prikaži akcije za vremenski period:</h2>
          <div className="filters__options">
            <div className="filters__field">
              <p className="filters__name">Od datuma:</p>
              <DateInput
                selected={historyStartDate}
                onChange={onHistoryStartDateChange}
                value={moment(historyStartDate).format(common.PREFERRED_FE_DATE_FORMAT)}
              />
            </div>
            <div className="filters__field">
              <p className="filters__name">Do datuma:</p>
              <DateInput
                selected={historyEndDate}
                onChange={onHistoryEndDateChange}
                value={moment(historyEndDate).format(common.PREFERRED_FE_DATE_FORMAT)}
              />
            </div>
          </div>
          <div className="filters__bottom">
            <div className="filters__ctas">
              <Button buttonText={"Potvrdi"} buttonColor={"blue"} event={applyFiltersHandler} />
              <Button buttonText={"Poništi"} buttonColor={"red"} event={clearFiltersHandler} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
