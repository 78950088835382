import React from "react";
import ReactDOM from "react-dom";

import { AuthenticationProvider } from "domain/Authentication";
import { GlobalRouter } from "router";

import "style/style.scss";
const Root = () => {
  return (
    <React.StrictMode>
      <AuthenticationProvider>
        <GlobalRouter />
      </AuthenticationProvider>
    </React.StrictMode>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));
