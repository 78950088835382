import React, { useContext, useState, useCallback, useMemo } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import jwtDecode from "jwt-decode";

import { GOOGLE_CLIENT_ID } from "environment";

import { AuthenticationService } from "../services";
import { useCookies } from "../../../shared/hooks";
import { AdminRole, SuperAdminRole, AccountantRole } from "../constants/roleConstants";

const allowedRoles = [AdminRole, SuperAdminRole, AccountantRole];

const AuthenticationContext = React.createContext();

export function useAuthentication() {
  return useContext(AuthenticationContext);
}

export function AuthenticationProvider({ children }) {
  const { getAccessToken } = useCookies(children);

  const [user, setUser] = useState(() => {
    try {
      return jwtDecode(getAccessToken())?.googleUser;
    } catch {
      return undefined;
    }
  });

  const [role, setRole] = useState(() => {
    try {
      return jwtDecode(getAccessToken())?.role;
    } catch {
      return undefined;
    }
  });

  const hasAccess = useMemo(() => allowedRoles.some((allowedRole) => allowedRole === role), [role]);

  const signIn = useCallback(({ credential }) => {
    AuthenticationService.logIn(credential).then((response) => {
      setUser(response.googleUser);
      setRole(response.role);
    });
  }, []);

  const signOut = useCallback(() => {
    AuthenticationService.logOut().then(() => {
      setUser();
      setRole();
    });
  }, []);

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <AuthenticationContext.Provider value={{ user, signIn, signOut, role, hasAccess }}>
        {children}
      </AuthenticationContext.Provider>
    </GoogleOAuthProvider>
  );
}
