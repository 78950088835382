import React, { useCallback, useEffect, useState } from "react";
import { usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { ArrowDown, ArrowUp } from "assets";
import clsx from "clsx";
import moment from "moment";
import { useSessionStorage } from "domain/App";
import { useFiltersContext } from "domain/Filters";
import { common } from "shared/constants/constants";
import { useHistoryTableLabels } from "domain/History/hooks/useHistoryTableLabels";
import { ItemsTablePagination } from "domain/Inventory";

import "./history-table.scss";

export const HistoryTable = React.forwardRef(({ data, isReadOnly, onChildScroll, resetScroll, onRowClick }, ref) => {
  const { columns } = useHistoryTableLabels();
  const { itemsPerView } = useFiltersContext();
  const [scrollActive, setScrollActive] = useState(false);
  const [lastVisitedPage, setVisitedPage] = useSessionStorage("items-table-last-visited-page", 0);

  const [numOfPages, setNumOfPages] = useState(1);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    setPageSize,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: lastVisitedPage || 0, pageSize: itemsPerView },
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    setPageSize(itemsPerView);
  }, [itemsPerView, setPageSize]);

  const ColumnElement = useCallback(({ cell, row }) => {
    switch (cell.column.id) {
      case "navigatorId":
        const [, navigatorId] = row.original.inventory.navigatorId.split("::");
        return <>{navigatorId ?? row.original.inventory.navigatorId}</>;
      case "itemId":
        return <>{row.original.inventory.itemId}</>;
      case "label":
        return <>{row.original.inventory.label}</>;
      case "invoice":
        return <>{row.original.inventory.invoice?.number}</>;
      case "createdAt":
        return (
          <>
            <div>{moment(cell.value).format(common.PREFERRED_FE_DATE_FORMAT)}</div>
            <div>{moment(cell.value).format(common.PREFERRED_TIME_FORMAT)}</div>
          </>
        );
      case "actionOwnerName":
        return (
          <>
            <div>{row.original.actionOwnerName}</div>
            <div>({row.original.actionOwnerEmail})</div>
          </>
        );
      default:
        return cell.render("Cell");
    }
  }, []);

  const onScrollHandler = (e) => {
    const itemYPos = e.currentTarget.scrollTop;
    setScrollActive(itemYPos > 20);
    onChildScroll(itemYPos);
  };

  useEffect(() => {
    setVisitedPage((previousPageIndex) => {
      if (pageIndex !== previousPageIndex) resetScroll();

      return pageIndex;
    });
  }, [pageIndex, setVisitedPage, resetScroll, isReadOnly]);

  // Added because of plugin warning message when pageCount is 0 at page load, so we are setting 1 until pageCounts loads from Table
  useEffect(() => {
    setNumOfPages(data?.length ? pageCount : 1);
  }, [data, pageCount]);

  const onClickHandler = (cell, row) => {
    onRowClick(row.original);
  };

  return data.length ? (
    <>
      <div className="items-table-wrap" onScroll={onScrollHandler} ref={ref}>
        <div {...getTableProps()} className="items-table">
          <div
            className={clsx("items-table__header history-items-table__grid", {
              "items-table__header--sticky": scrollActive,
            })}
            style={{ "--grid-size": headerGroups[0]?.headers?.length }}
          >
            {headerGroups[0].headers.map((column) => (
              <div
                {...column.getHeaderProps(column.getSortByToggleProps())}
                key={column.id}
                className="items-table__heading "
              >
                {column.render("Header")}
                <span className="items-table__sort-icon">
                  {column.isSorted ? column.isSortedDesc ? <ArrowDown /> : <ArrowUp /> : ""}
                </span>
              </div>
            ))}
          </div>
          <div {...getTableBodyProps()} className="items-table__body">
            {page.map((row) => {
              prepareRow(row);

              return (
                <div
                  key={row.id}
                  {...row.getRowProps()}
                  className={clsx("items-table__row history-items-table__grid")}
                  style={{ "--grid-size": row.cells.length }}
                >
                  {row.cells.map((cell, index) => (
                    <div
                      {...cell.getCellProps()}
                      key={index}
                      className={clsx("items-table__data")}
                      onClick={() => onClickHandler(cell, row)}
                      data-label={cell.column.render("Header")}
                    >
                      <div>
                        <ColumnElement cell={cell} row={row} />
                      </div>
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <ItemsTablePagination pageCount={numOfPages} pageIndex={pageIndex} gotoPage={gotoPage} />
    </>
  ) : (
    <div className="items-table__loading">Ne postoji ni jedan podatak sa selektovanim filterima.</div>
  );
});
