export function useDownloadFile() {
  function handleDownloadFile(blob, fileName) {
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }

  return {
    onDownloadFile: (blob, fileName) => handleDownloadFile(blob, fileName),
  };
}
