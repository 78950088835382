import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useCensusContext } from "../../context";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./census-details.scss";
import { Button, Dropdown, ModalConfirmation, useModalContext } from "../../../../shared";
import { useCensusDetailsTableLabels } from "../../hooks/useCensusDetailsTableLabels";
import { EditInventoryCensusForm } from "../../../Inventory";
import { CensusProgress } from "../census-progress/census-progress";
import { CensusFilter } from "../census-filter/census-filter";
import { InventoryMatchingState } from "../../enums";
import { CensusDataContent } from "../census-data-content/census-data-content";
import { CENSUS } from "../../../../router";
import { exportFileFormats, ExportFileFormat } from "../../../../shared/constants/constants";
import { useDownloadFile } from "domain/Export";

export const CensusDetails = () => {
  const {
    getCensusInventories,
    getCensusProgress,
    getNonCensusedInventory,
    censusInventories,
    updateInventoryCensus,
    notInCensusInventories,
    progress,
    closeCensus,
    getIsMatchingText,
    isActiveCensus,
    exportData,
  } = useCensusContext();

  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [showNotCensused, setShowNotCensused] = useState(false);
  const [showDetailedProgress, setShowDetailedProgress] = useState(false);
  const [isMatchingFilter, setIsMatchingFilter] = useState(InventoryMatchingState.ALL_ITEMS);
  const [exportFormat, setExportFormat] = useState(ExportFileFormat.CSV);
  const [inventoryId, setInventoryId] = useState(null);
  const { id: censusId } = useParams();
  const censusYear = location.state.year;
  const { onDownloadFile } = useDownloadFile();

  const { createModal } = useModalContext();
  const {
    element: CloseNewItemModalConfirmation,
    open: openConfirmationModal,
    close: closeConfirmationModal,
  } = useMemo(() => createModal(), [createModal]);
  const {
    element: EditCensusModal,
    open: openEditCensusModal,
    close: closeEditCensusModal,
  } = useMemo(() => createModal(), [createModal]);

  const handleCloseCensusButtonClick = () => {
    openConfirmationModal();
  };

  const handleConfirmCloseCensus = () => {
    closeCensus(id)
      .then(() => {
        closeConfirmationModal();
        navigate(CENSUS);
      })
      .catch((error) => alert(error));
  };

  const handleEditClick = (inventoryId) => {
    setInventoryId(inventoryId);
    openEditCensusModal();
  };

  useEffect(() => {
    const queryParams = { isMatching: isMatchingFilter };
    getCensusInventories(id, queryParams);
  }, [getCensusInventories, id, isMatchingFilter]);

  useEffect(() => {
    getCensusProgress(id);
  }, [getCensusProgress, id]);

  useEffect(() => {
    if (showNotCensused) {
      getNonCensusedInventory(id);
    }
  }, [getNonCensusedInventory, showNotCensused, id]);

  const toggleDetailedProgress = () => {
    setShowDetailedProgress(!showDetailedProgress);
  };

  const handleRowClick = (id) => {
    window.open(`/item/${id}`, "_blank", "noreferrer");
  };

  const onExportFormatChange = (selectedOption) => {
    setExportFormat(selectedOption[0].key);
  };

  const handleExport = () => {
    const exportDataInfo = {
      data: data,
      exportFormat: exportFormat,
    };

    exportData(exportDataInfo)
      .then((blob) => {
        onDownloadFile(blob, `export${location.state.year}.${exportDataInfo.exportFormat.toLowerCase()}`);
      })
      .catch(() => {
        alert("There was an error exporting the data");
      });
  };

  const onEditCensus = useCallback(
    (editedCensus) => {
      updateInventoryCensus(editedCensus)
        .then(() => {
          getCensusInventories(id);
          closeEditCensusModal();
          getCensusProgress(id);
        })
        .catch((error) => alert(error));
    },
    [getCensusInventories, updateInventoryCensus, closeEditCensusModal, getCensusProgress, id]
  );

  const { columns } = useCensusDetailsTableLabels();
  const data = showNotCensused ? notInCensusInventories ?? [] : censusInventories || [];

  return (
    <>
      <div className="census-header-wrapper">
        <CensusProgress
          progress={progress}
          showDetailedProgress={showDetailedProgress}
          toggleDetailedProgress={toggleDetailedProgress}
        />

        <div className="census-options">
          <Button buttonText={"Eksportuj podatke"} buttonColor={"blue"} event={handleExport} />

          <div className="filters__dropdown">
            <Dropdown choices={exportFileFormats} defaultValue={[{ name: "csv" }]} onChange={onExportFormatChange} />
          </div>

          <div className="close-button" hidden={!isActiveCensus(censusId)}>
            <Button buttonText="Zatvori popis" buttonColor={"red"} event={handleCloseCensusButtonClick} />
          </div>
        </div>

        <div className="filters-container">
          <h1>{showNotCensused ? `Nepopisan inventar za ${censusYear}. godinu` : `Popis  ${censusYear}.`}</h1>
          <CensusFilter
            isMatchingFilter={isMatchingFilter}
            setIsMatchingFilter={setIsMatchingFilter}
            showNotCensused={showNotCensused}
            setShowNotCensused={setShowNotCensused}
          />
        </div>
      </div>

      <div className="scroll">
        <CensusDataContent
          data={data}
          columns={columns}
          handleEditClick={handleEditClick}
          handleRowClick={handleRowClick}
          getIsMatchingText={getIsMatchingText}
          isMatchingFilter={isMatchingFilter}
          isActiveCensus={() => isActiveCensus(censusId)}
          showNotCensused={showNotCensused}
          censusYear={location.state.year}
        ></CensusDataContent>

        <CloseNewItemModalConfirmation>
          <ModalConfirmation
            onSubmit={handleConfirmCloseCensus}
            onCancel={closeConfirmationModal}
            text={<p>Da li ste sigurni da želite da zatvorite popis?</p>}
          />
        </CloseNewItemModalConfirmation>
        <EditCensusModal>
          <EditInventoryCensusForm
            inventoryId={inventoryId}
            censusId={censusId}
            onSubmit={onEditCensus}
            onCancel={closeEditCensusModal}
          />
        </EditCensusModal>
      </div>
    </>
  );
};
