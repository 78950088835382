import { useMemo } from "react";
import { Dropdown } from "shared";
import { useCategoriesContext } from "domain/Category";
import "./category-wrapper-form.scss";

export function CategoryWrapperForm({
  children,
  values,
  handleBlur,
  touched,
  isDropdownDirty,
  onDropdownChange,
  isItemUpdate,
}) {
  const { categories } = useCategoriesContext();
  const isCategorySelected = useMemo(() => Object.keys(values.category).length !== 0, [values]);

  return (
    <>
      {!isItemUpdate && (
        <div className="dropdown-form__wrapper">
          <label>
            Kategorija<span className="text-input__required">*</span>
          </label>
          <Dropdown
            choices={categories}
            onChange={onDropdownChange("category")}
            allSelectedLabel="Odaberite kategoriju"
            defaultValue={[values.category]}
            handleBlur={handleBlur}
            dropdownError={isDropdownDirty("category")}
            touched={touched}
            name={"category"}
          />
        </div>
      )}
      {isCategorySelected && children}
    </>
  );
}
