import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useNavigate, Outlet, useLocation } from "react-router-dom";

import { useAuthentication } from "domain/Authentication";
import { UserPanel, UserNavigation } from "domain/User";

import { SideBar } from "shared";

import { ApplicationContextProvider } from "../../context";

import "./app.component.scss";
import { CensusIndicator } from "domain/Census";
export const App = () => {
  const { user } = useAuthentication();
  const navigate = useNavigate();
  let { pathname } = useLocation();
  const [isNavMenuClicked, setIsNavMenuClicked] = useState(false);

  useEffect(() => {
    if (!user) navigate("/login", { state: pathname });
  }, [navigate, user, pathname]);

  if (!user) return null;

  const isScanPage = pathname.includes("scan");
  
  return (
    <ApplicationContextProvider>
      <div className={clsx("app", { "app--nav-open": isNavMenuClicked })}>
        {!isScanPage && (
          <div className="app__sidebar">
            <SideBar>
              <UserPanel />
              <CensusIndicator />
              <UserNavigation />
            </SideBar>
          </div>
        )}
        <main className={clsx("app__content", { "app__content--scan": isScanPage })}>
          <Outlet context={[isNavMenuClicked, setIsNavMenuClicked]} />
        </main>
      </div>
    </ApplicationContextProvider>
  );
};
