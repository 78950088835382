import { ItemPartsTable, ItemTypeLabel, useInventoryContext } from "domain/Inventory";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { ArrowDown, ArrowUp } from "assets";
import clsx from "clsx";
import moment from "moment";
import { useSessionStorage } from "domain/App";
import { useFiltersContext } from "domain/Filters";
import { useCensusContext } from "domain/Census";
import { FaNetworkWired } from "react-icons/fa";
import { Button } from "shared";
import { common, InventoryType } from "shared/constants/constants";
import { ItemsTableCheckbox, ItemsTablePagination } from "..";
import { useItemTableLabels } from "../../hooks";

import "./items-table.scss";
export const ItemsTable = React.forwardRef(
  ({ displayedColumns, data, isReadOnly, onChildScroll, resetScroll, onRowClick }, ref) => {
    let navigate = useNavigate();
    const { updateSelection } = useInventoryContext();
    const { activeCensus } = useCensusContext();
    const { columns } = useItemTableLabels(displayedColumns);
    const { itemsPerView } = useFiltersContext();
    const [scrollActive, setScrollActive] = useState(false);
    const [lastVisitedPage, setVisitedPage] = useSessionStorage("items-table-last-visited-page", 0);
    const [expandSubitems, setExpandSubitems] = useState(null);

    const [numOfPages, setNumOfPages] = useState(1);

    const goRouteId = useCallback(
      (id) => {
        navigate(`/item/${id}`);
      },
      [navigate]
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      pageCount,
      gotoPage,
      selectedFlatRows,
      setPageSize,
      state: { pageIndex },
    } = useTable(
      {
        columns,
        data,
        initialState: { pageIndex: lastVisitedPage || 0, pageSize: itemsPerView },
      },
      useSortBy,
      usePagination,
      useRowSelect,
      (hooks) => {
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => <ItemsTableCheckbox {...getToggleAllRowsSelectedProps()} />,
            Cell: ({ row }) => {
              return !isReadOnly ? <ItemsTableCheckbox {...row.getToggleRowSelectedProps()} /> : null;
            },
          },
          ...columns,
        ]);
      }
    );

    useEffect(() => {
      setPageSize(itemsPerView);
    }, [itemsPerView, setPageSize]);

    useEffect(() => {
      updateSelection(selectedFlatRows);
    }, [selectedFlatRows, updateSelection]);

    const expandHandler = useCallback(
      (id) => {
        if (expandSubitems === id) {
          return setExpandSubitems(null);
        }
        setExpandSubitems(id);
      },
      [expandSubitems]
    );

    const ColumnElement = useCallback(
      ({ cell, row }) => {
        switch (cell.column.id) {
          case "button":
            const partsLength = row.original.parts.length;
            return !isReadOnly && partsLength > 0 ? (
              <div className="items-table__toggle">
                <button
                  className={clsx("items-table__btn", { "items-table__btn--active": expandSubitems })}
                  onClick={() => expandHandler(row.id)}
                >
                  <FaNetworkWired className="icon" />
                </button>
                <span className="items-table__parts-amount">{partsLength}</span>
              </div>
            ) : null;
          case "type":
            return <ItemTypeLabel type={cell.value} />;
          case "itemId":
            return <div>{cell.value}</div>;
          case "navigatorId":
            const [, navigatorId] = row.original.navigatorId.split("::");
            return <div>{navigatorId ?? row.original.navigatorId}</div>;
          case "location.office":
            const { office, city } = row.original.location;
            return (
              <>
                <div>{office}</div>
                <div className="items-table__data__cell-sub-text">{city}</div>
              </>
            );
          case "company.owner":
            return <div>{row.original.owner.name}</div>;
          case "invoice.number":
            const invoiceNumber = row.original?.invoice?.number;
            return (
              <>
                <div>{invoiceNumber ? invoiceNumber : "Nepoznata faktura"}</div>
              </>
            );
          case "invoice.purchaseDate":
            const purchaseDate = row.original?.invoice?.purchaseDate;
            return (
              <>
                <div>
                  {purchaseDate ? moment(purchaseDate).format(common.PREFERRED_FE_DATE_FORMAT) : "Nepoznata faktura"}
                </div>
              </>
            );
          case "activationDate":
            const activationDate = row.original?.activationDate;
            return (
              <>
                <div>
                  {activationDate ? moment(activationDate).format(common.PREFERRED_FE_DATE_FORMAT) : "Neaktivirano"}
                </div>
              </>
            );
          case "supplier":
            const supplier = row.original?.supplier?.name ? row.original?.supplier?.name : "Nepoznat dobavljač";
            return (
              <>
                <div>{supplier}</div>
              </>
            );
          default:
            return cell.render("Cell");
        }
      },
      [expandHandler, expandSubitems, isReadOnly]
    );

    const onScrollHandler = (e) => {
      const itemYPos = e.currentTarget.scrollTop;
      setScrollActive(itemYPos > 20);
      onChildScroll(itemYPos);
    };

    const onClickHandler = (cell, row) => {
      if (!isReadOnly && onRowClick) {
        onRowClick(row.original);
      } else if (!isReadOnly && !["selection", "button"].includes(cell.column.id)) {
        goRouteId(row.original.itemId);
      }
    };

    useEffect(() => {
      setVisitedPage((previousPageIndex) => {
        if (pageIndex !== previousPageIndex) resetScroll();

        return pageIndex;
      });
    }, [pageIndex, setVisitedPage, resetScroll, isReadOnly]);

    // Added because of plugin warning message when pageCount is 0 at page load, so we are setting 1 until pageCounts loads from Table
    useEffect(() => {
      setNumOfPages(data?.length ? pageCount : 1);
    }, [data, pageCount]);

    return data.length ? (
      <>
        <div className="items-table-wrap" onScroll={onScrollHandler} ref={ref}>
          <div {...getTableProps()} className="items-table">
            <div
              className={clsx("items-table__header items-table__grid", { "items-table__header--sticky": scrollActive })}
              style={{ "--grid-size": headerGroups[0]?.headers?.length }}
            >
              {headerGroups[0].headers.map((column) =>
                column.id !== "selection" || !isReadOnly ? (
                  <div
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={column.id}
                    className="items-table__heading "
                  >
                    {column.render("Header")}
                    <span className="items-table__sort-icon">
                      {column.isSorted ? column.isSortedDesc ? <ArrowDown /> : <ArrowUp /> : ""}
                    </span>
                  </div>
                ) : (
                  <div key={column.id}></div>
                )
              )}
            </div>
            <div {...getTableBodyProps()} className="items-table__body">
              {page.map((row) => {
                prepareRow(row);

                const itemCensusStatus =
                  row.original.activeCensus?.isMatching === undefined
                    ? "incomplete"
                    : row.original.activeCensus.isMatching
                      ? "completed"
                      : "pending";

                return (
                  <div
                    key={row.id}
                    {...row.getRowProps()}
                    className={clsx("items-table__row items-table__grid", {
                      "items-table__row--active": expandSubitems === row.id,
                    })}
                    style={{ "--grid-size": row.cells.length }}
                  >
                    {row.cells.map((cell, index) => (
                      <div
                        {...cell.getCellProps()}
                        key={index}
                        className={clsx(
                          "items-table__data",
                          { "items-table__data--readonly": isReadOnly },
                          {
                            "items-table__data--mobile-hide-border": cell.column.id === "button",
                          },
                          {
                            "items-table__data--mobile-hide-label": cell.column.id === "selection",
                          }
                        )}
                        onClick={() => onClickHandler(cell, row)}
                        data-label={cell.column.render("Header")}
                      >
                        <div>
                          <ColumnElement cell={cell} row={row} />
                        </div>
                        {activeCensus && (
                          <div
                            className={clsx("census-indicator", {
                              [itemCensusStatus]: true,
                            })}
                          ></div>
                        )}
                      </div>
                    ))}
                    {row.original.parts.length > 0 ? <ItemPartsTable row={row} /> : null}
                    <div className="items-table__row-button">
                      <Button
                        buttonText={"Više informacija"}
                        buttonColor={"blue"}
                        event={() => goRouteId(row.original.itemId)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <ItemsTablePagination pageCount={numOfPages} pageIndex={pageIndex} gotoPage={gotoPage} />
        <div className="items-table__legend">
          <ul className="items-table__legend-list">
            <li className="items-table__legend-list-item">
              <ItemTypeLabel type={InventoryType.SMALL_INVNENTORY} /> - {common.SMALL_INVNENTORY}
            </li>
            <li className="items-table__legend-list-item">
              <ItemTypeLabel type={InventoryType.FIXED_ASSET} /> - {common.FIXED_ASSET}
            </li>
            <li className="items-table__legend-list-item">
              <ItemTypeLabel type={InventoryType.IMMATERIAL_ASSET} /> - {common.IMMATERIAL_ASSET}
            </li>
          </ul>
        </div>
      </>
    ) : (
      <div className="items-table__loading">Ne postoji nijedan inventar sa selektovanim filterima.</div>
    );
  }
);
