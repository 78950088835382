import React from "react";

import { useInventoryContext } from "../../../context";
import { Button } from "shared";
import { ConfigurationChangesSection } from "../configuration-changes-section/configuration-changes-section";

import "./configuration-changes-form.scss";
export const ConfigurationChangesForm = ({ onSubmit, onCancel, data, isLoading }) => {
  const { inventory, inventoryParts } = data;
  const { changes } = useInventoryContext();

  return (
    <form>
      <div className="configuration-changes">
        <h2 className="configuration-changes__title">
          <span>Dodavanje delova na predmet</span>
          <br />
          {inventory.label}
        </h2>
        <div className="configuration-changes__text_section">
          <p>Nakon dodavanja delova</p>
          <ul className="configuration-changes__list">
            {inventoryParts?.map((item) => (
              <li className="configuration-changes__list-item" key={item.id}>
                {item.label}
              </li>
            ))}
          </ul>
          <p>desiće se izmena sledećih stavki:</p>
        </div>
        <div className="configuration-changes__changes_section">
          {changes && <ConfigurationChangesSection data={changes}></ConfigurationChangesSection>}
        </div>
      </div>
      <div className="configuration-changes__controls">
        <Button buttonText={"Otkaži"} buttonColor={"gray"} event={onCancel} />
        <Button
          type="submit"
          buttonText={"Potvrdi"}
          buttonColor={"green"}
          event={(e) => onSubmit(e)}
          spinnerColor={"light"}
          isLoading={isLoading}
        />
      </div>
    </form>
  );
};
