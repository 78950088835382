import React, { useState } from "react";

import { Button, TextInput } from "shared";

export const EditInventoryCensusForm = ({ onSubmit, onCancel, isBtnLoading, inventoryId, censusId }) => {
  const [editedCensus, setEditedCensus] = useState({ inventoryId, isMatching: false, description: "", censusId });
  const onInputChange = (event) => {
    const { name: key, value } = event.target;
    setEditedCensus((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const onCheckboxChange = (event) => {
    const { checked, name } = event.target;
    setEditedCensus((prev) => ({ ...prev, [name]: checked }));
  };
  const confirm = () => {
    onSubmit(editedCensus);
  };
  return (
    <>
      <div className="create-company">
        <h2 className="create-company__title">Izmena popisanog inventara</h2>
        <TextInput
          name="isMatching"
          type="checkbox"
          onChange={onCheckboxChange}
          value={editedCensus.isMatching}
          label="Podaci se poklapaju"
        />
        <TextInput
          name="description"
          onChange={onInputChange}
          autoComplete="off"
          placeholder="Opis"
          value={editedCensus.description || ""}
          maxLength={50}
        />
      </div>
      <div className="create-company__controls">
        <Button
          buttonText={"Potvrdi"}
          buttonColor={"red"}
          event={confirm}
          spinnerColor={"light"}
          isLoading={isBtnLoading}
        />
        <Button buttonText={"Otkaži"} buttonColor={"gray"} event={onCancel} />
      </div>
    </>
  );
};
