import clsx from "clsx";
import moment from "moment";
import { useMemo } from "react";
import { common } from "shared/constants/constants";
import { ItemTypeLabel } from "domain/Inventory";
import "./history-extra-info-item.scss";

export const HistoryExtraInfoItem = ({ label = "", extraBefore, extraAfter, type = false, isDate = false }) => {
  const isBeforeAvailable = extraBefore || (typeof extraBefore === "string" && extraBefore.length > 0);
  const isAfterAvailable =
    typeof extraAfter !== "undefined" || (typeof extraAfter === "string" && extraAfter.length > 0);

  const extraAfterValue = useMemo(() => {
    if (!isAfterAvailable || !extraAfter || !label) return null;
    if (type) {
      return <ItemTypeLabel type={extraAfter} />;
    } else if (isDate && moment(new Date(extraAfter)).isValid()) {
      return moment(new Date(extraAfter)).format(common.PREFERRED_FE_DATE_FORMAT);
    } else {
      return extraAfter;
    }
  }, [extraAfter, isAfterAvailable, isDate, type, label]);

  if (!isAfterAvailable || (!type && !extraAfter) || !label) return null;

  const beforeSmallInventory = type ? <ItemTypeLabel type={extraBefore} /> : extraBefore;

  return (
    <div className="history-item__extra-item">
      {isBeforeAvailable && <div className="history-item__extra-item-label">{label}</div>}
      <div className="history-item__extra-item-values">
        <div
          className={clsx("history-item__extra-item-before", {
            "history-item__extra-item-before--with-label": !isBeforeAvailable,
          })}
        >
          {isBeforeAvailable ? beforeSmallInventory : label}
        </div>
        <div className="history-item__extra-item-separator">
          <span className="history-item__chevron history-item__chevron--right"></span>
        </div>
        <div className="history-item__extra-item-after">{extraAfterValue}</div>
      </div>
    </div>
  );
};
