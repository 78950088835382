import { api } from "../../../shared";

const url = "/api/invoices";

async function get(params) {
  return api
    .inventar({
      method: "get",
      url,
      params,
    })
    .then((response) => response.data);
}

export const InvoiceService = {
  get,
};
