import React from "react";
import { StickerPreview } from "../sticker-preview/sticker-preview";
import { SCAN } from "router";
import { ItemSticker } from "../item-sticker/item-sticker";

import clsx from "clsx";
import "./parent-group-sticker.scss";
export const ParentGroupSticker = React.forwardRef(({ item, vertical = false }, ref) => {
  const { itemId, company, category, parts, location, assignedTo = "Neraspoređeno" } = item;
  const { address, office } = location || {};

  return (
    <div className={clsx({ vertical })} ref={ref}>
      <StickerPreview
        title={assignedTo}
        subtitle={company?.name}
        description={office || address}
        footer={[category.name, `${item.parts.length} part${item.parts.length > 1 ? "s" : ""}`]}
        url={`${window.location.origin}${SCAN}/${itemId}`}
        reversed
      />
      {parts.map((part) => (
        <ItemSticker key={part.itemId} item={part} />
      ))}
    </div>
  );
});
